import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-kolektor-penagihan-add',
  templateUrl: './kolektor-penagihan-add.component.html',
  styleUrls: ['./kolektor-penagihan-add.component.scss'],
})
export class KolektorPenagihanAddComponent implements OnInit {

  load: boolean = false;
  saving: any = {};
  idData: any;

  detData: any = {};
  det: any;
  tampilData: boolean = false;
 
  cabang: any = '';
  grup: any = '';
  nama: any = '';
  nip: any = '';
  jabatan: any = '';
  phone: any = '';
  email: any = '';
  username: any = '';
  password: any = '';

  usertype: any = '';

  selct: any = '';


  disabledInput: boolean = false;
  detList: any;

  id: any;
  ids: any;

  form: any;

  constructor(
    public api: ApiService,
    public util: UtilService,
    public navParams: NavParams
  ) {
    this.id = this.navParams.data.id;
    try {
      this.ids = JSON.parse(this.navParams.data.ids);
    } catch (error) {
      this.ids = '';
    }
    console.log('id',this.id);
    console.log('ids',this.ids);
   }

  ngOnInit() {
      this.detailData()
  }

  pilih(p) {
    console.log(p);
    if (this.selct == '') {
      this.selct = p;
    } else if (this.selct == p) {
      // this.selct = '';
    } else {
      this.selct = p;
    }
    console.log('selct', this.selct);
  }

  detailData(){
    this.api.get('data/master/kolektor')
      .subscribe((data) => {
        this.detData = data;
        if (this.detData.error == 0) {
          this.detList = this.detData.data;
          setTimeout(() => {
            this.load = false;
            }, 1000);
        } else {
          this.load = false;
          this.util.showToast(this.detData.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  save(){
    this.load = true;
    var arrayData = [this.id];
    if(this.ids == ''){
      this.form = {
        contract_id: arrayData,
        assign_to: this.selct,
      };
    }else{
      this.form = {
        contract_id: JSON.parse(this.ids),
        assign_to: this.selct,
      };
    }
    this.api.post('backoffice/kolektor/penagihan/assignto', this.form)
      .subscribe((data) => {
        this.saving = data;
        if (this.saving.error == 0) {
          this.load = false;
          this.util.showToast(this.saving.message, 'success', 'bottom');
          this.api.dismissData('','confirm');
        } else {
          this.load = false;
          this.util.showToast(this.saving.message, 'danger', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  handleSelection(selectedValue: string, model: any) {
    if (model == 'cabang') {
      this.cabang = selectedValue;
    } else if (model == 'usertype') {
      this.usertype = selectedValue;
    } 
  }

  dismiss() {
    this.api.dismiss()
  }
}
