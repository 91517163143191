import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, NavParams, Platform, ToastController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preview-photo-laporan',
  templateUrl: './preview-photo-laporan.component.html',
  styleUrls: ['./preview-photo-laporan.component.scss'],
})
export class PreviewPhotoLaporanComponent implements OnInit {

 
  load: boolean = false;
  hasil: any = {};
  hsl: any;
  button: boolean = false;


  url: any;
  path: any;
  photoKtpPemohon: any;
  loadktpPemohon: boolean = false;
  photoKtpPemohonx: any;
  imageUrl?: string;

  constructor(
    public route: Router,
    public api: ApiService,
    public util: UtilService,
    public navParams: NavParams,
    public modalController: ModalController,
    public platform: Platform,
    // public androidPermissions: AndroidPermissions,
    public toastCtrl: ToastController,
    public dom: DomSanitizer,
    public http: HttpClient
  ) {

   }

  dismiss() {
    this.api.dismiss()
  }

  ngOnInit() {
    this.url = this.navParams.data.id;
    console.log('url',this.url)
    this.transform(this.url)
  }

  ionViewWillEnter() {
    // this.checkAndRequestLocationPermission()
  }

  async transform(src: string) {
    this.loadktpPemohon = true;
    var that = this;
    this.api.photo(src)
      .then((response) => response.blob())
      .then((imageBlob) => {
        const reader = new FileReader();
        this.loadktpPemohon = false;
        reader.onloadend = () => {
          console.log('load image with fetch');
          that.photoKtpPemohonx = reader.result;
          that.path = this.dom.bypassSecurityTrustUrl(that.photoKtpPemohonx) as string;
          console.log('url im', that.path)
          // this.http.get(that.path, {responseType: 'blob'});
        };
        reader.readAsDataURL(imageBlob);
      });
  }

  


}
