
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { HTTP } from '@ionic-native/http/ngx';
// import Swal from 'sweetalert2';
import { forkJoin } from 'rxjs';
import { UtilService } from './util/util.service';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
// import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  baseUrl: any = '';
  mediaURL: any = '';
  apikey: any = '';
  authUrl: any = '';
  usertoken: any = '';
  loadktpPemohon: boolean = false;
  photoKtpPemohonx: any
  imageUrl: any;

  user: any = {};
  menu: any = {};

  constructor(
    private http: HttpClient,
    public util: UtilService,
    public route: Router,
    public navctrl: NavController,
    public modalController: ModalController,
    public dom: DomSanitizer,

  ) {
    this.baseUrl = environment.restURL.baseUrl;
    // this.apikey = environment.restURL.apikey;
    this.authUrl = environment.restURL.authUrl;
    this.usertoken = localStorage.getItem('usertoken') || 'null';
    console.log('Token',this.usertoken);
  }

  JSON_to_URLEncoded(element: any, key?: any, list?: any) {
    let new_list = list || [];
    if (typeof element === 'object') {
      for (let idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + '[' + idx + ']' : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + '=' + encodeURIComponent(element));
    }
    return new_list.join('&');
  }

  getUserMenu(){
    try {
      this.user = JSON.parse(localStorage.getItem('user')); 
      this.menu = this.user.access_menu;
    } catch (error) {
      this.menu = '';
    }
    
    return this.menu;
  }


  postAuth(url: any, body: any) {
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        // .set('apikey', this.apikey)
    };
    const param = body;
    return this.http.post(this.authUrl + url, param, header);
  }

  post(url: any, body: any) {
    const header = {
      headers: new HttpHeaders()
      // .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.usertoken)
    };
    const param = body;
    // console.log(param);
    return this.http.post(this.baseUrl + url, param, header);
  }

  postExcel(url: any, body: any) {
    const header = {
      headers: new HttpHeaders()
      // .set('Accept', 'application/json')
      // .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.usertoken)
    };
    const param = body;
    // console.log(param);
    return this.http.post(this.baseUrl + url, param, header);
  }

  put(url: any, body: any) {
    const header = {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.usertoken)
    };
    // const param = this.JSON_to_URLEncoded(body);
    const param = body;
    return this.http.put(this.baseUrl + url, param, header);
  }

  putNoBody(url: any) {
    const header = {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.usertoken)
    };
    // const param = this.JSON_to_URLEncoded(body);
    const param = '';
    return this.http.put(this.baseUrl + url, param, header);
  }

  postCheckout(url: any, body: any) {
    const header = {
      headers: new HttpHeaders()
        .set('apikey', this.apikey)
        .set('token', this.usertoken)
    };
    return this.http.post(this.baseUrl + url, body, header);
  }

  delete(url: any) {
    const header = {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.usertoken)
    };
    return this.http.delete(this.baseUrl + url, header);
  }

  deleteWithBody(url: any, body: any) {
    const param = body;
    const header = {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.usertoken),
      body: param
    };
    
    // console.log(param);
    return this.http.delete(this.baseUrl + url, header);
  }

  editWithInput(url: any, body: any) {
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('apikey', this.apikey)
        .set('token', this.usertoken)
    };
    const param = this.JSON_to_URLEncoded(body);
    return this.http.put(this.baseUrl + url, param, header);
  }

  editFormData(url: any, body: any) {
    const header = {
      headers: new HttpHeaders()
        // .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('apikey', this.apikey)
        .set('token', this.usertoken)
    };
    // const param = this.JSON_to_URLEncoded(body);
    return this.http.put(this.baseUrl + url, body, header);
  }

  edit(url: any) {
    const header = {
      headers: new HttpHeaders()
        // .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('apikey', this.apikey)
        .set('token', this.usertoken)
    };
    return this.http.delete(this.baseUrl + url, header);
  }

  
  get(url: any) {
    const header = {
      headers: new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.usertoken)
    };
    return this.http.get(this.baseUrl + url, header);
  }

  uploadFile(files: File[]) {
    const formData = new FormData();
    Array.from(files).forEach(f => formData.append('userfile', f));
    return this.http.post(this.baseUrl + 'users/upload_image', formData);
  }

  uploadFileAndForm(url: any,formData: any) {
    const header = {
      headers: new HttpHeaders()
      // .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.usertoken)
    };
    return this.http.post(this.baseUrl + url, formData, header);
  }

  formError(error) {
    // const header = {
    //   headers: new HttpHeaders()
    //     .set('apikey', this.apikey)
    //     .set('token', this.usertoken)
    // };
    // return this.http.post(this.baseUrl + url, formData, header);
  }

  editAndUpload(url: any,formData: any) {
    const header = {
      headers: new HttpHeaders()
        .set('apikey', this.apikey)
        .set('token', this.usertoken)
    };
    return this.http.put(this.baseUrl + url, formData, header);
  }


  externalGet(url: any) {
    return this.http.get(url);
  }

  httpGet(url: any, key: any) {
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', `Bearer ${key}`)
    };

    return this.http.get(url, header);
  }

  errorCond(error: any){
    if (error.error == 'Unauthorized') {
      this.fungsilogout();
    }  else if (error.error.error == 'invalid_request') {
      this.util.showToast(error.error.error_description, 'danger', 'bottom');
      this.fungsilogout();
    } else if (error.error.error == 1) {
      console.log('error1')
      this.util.showToast(error.error.message, 'danger', 'bottom');
    } else if (error.error.message) {
      this.util.showToast(error.error.message, 'danger', 'bottom');
    } else {
      this.util.showToast(error.message, 'danger', 'bottom');
    }
  }

  errorCondLogin(error){
    try {
      this.util.showToast(error.error.errors[0], 'danger', 'bottom');
    } catch (error) {
      try {
        this.util.showToast(error.error.errors, 'danger', 'bottom');
      } catch (error) {
        this.util.showToast('Terjadi Kesalahan', 'danger', 'bottom');
      }
    }
  }


  fungsilogout() {
    this.util.showToast('Sesi anda berakhir, Silahkan Login Kembali', 'success', 'bottom');
    // localStorage.removeItem('usertoken');
    // localStorage.removeItem('user');
    localStorage.clear();
    window.location.reload();
    this.route.navigate(['./login']);
    
  }

  backPage(){
    return this.navctrl.back();
  }

  dismiss(){
    this.modalController.dismiss();
    try {
    } catch (exception) {
      console.log("exception", exception);
    }
  }

  dismissData(data: any, action: any){
    this.modalController.dismiss(data, action);
    try {
    } catch (exception) {
      console.log("exception", exception);
    }
  }

  tes(){
    return 'tessss'
  }

  photo(src: string) {
    let fetchOptions = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.usertoken,
      },
    };
    var that = this;
    fetch(src,fetchOptions)
    return fetch(src,fetchOptions);
  }

  tokenPhoto(src: string) {
    let fetchOptions = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.usertoken,
      },
    };
    var that = this;
    fetch(
      src,
      fetchOptions
    )
      .then((response) => response.blob())
      .then((imageBlob) => {
        const reader = new FileReader();
        this.loadktpPemohon = false;
        reader.onloadend = () => {
          console.log('load image with fetch');
          that.photoKtpPemohonx = reader.result;
          that.imageUrl = this.dom.bypassSecurityTrustUrl(that.photoKtpPemohonx) as string;
          console.log('url im', that.imageUrl)
        };
        // return 'tesssss';
        reader.readAsDataURL(imageBlob);
      });
      return that.imageUrl;
  }
  

}
