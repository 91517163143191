import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-produk-edit',
  templateUrl: './produk-edit.component.html',
  styleUrls: ['./produk-edit.component.scss'],
})
export class ProdukEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
