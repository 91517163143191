import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-user-admin-add',
  templateUrl: './user-admin-add.component.html',
  styleUrls: ['./user-admin-add.component.scss'],
})
export class UserAdminAddComponent implements OnInit {

  load: boolean = false;
  saving: any = {};
  idData: any;

  detData: any = {};
  det: any;
  tampilData: boolean = false;
 
  cabang: any = '';
  grup: any = '';
  nama: any = '';
  nip: any = '';
  jabatan: any = '';
  phone: any = '';
  email: any = '';
  username: any = '';
  password: any = '';

  disabledInput: boolean = false;

  constructor(
    public api: ApiService,
    public util: UtilService,
    public navParams: NavParams
  ) {
    
   }

  ngOnInit() {
    this.idData = this.navParams.data.id;
    console.log(this.idData);
    if(this.idData){
      this.disabledInput = true;
      this.detailData()
    }else{
      this.disabledInput = false;
      this.tampilData = true;
    }
  }

  detailData(){
    this.api.get('backoffice/manajemen-user/admin/list/'+this.idData)
      .subscribe((data) => {
        this.detData = data;
        if (this.detData.error == 0) {
          this.cabang = this.detData.data.branch_id;
          this.grup = this.detData.data.group;
          this.nama = this.detData.data.name;
          this.nip = this.detData.data.nip;
          this.jabatan = this.detData.data.jabatan;
          this.phone = this.detData.data.phone_number;
          this.email = this.detData.data.email;
          this.username = this.detData.data.username;
          setTimeout(() => {
            this.tampilData = true;
          this.load = false;
            }, 1500);
          
        } else {
          this.load = false;
          this.util.showToast(this.detData.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  save(){
    this.load = true;
    let form = {
      group_user: this.grup,
      jabatan: this.jabatan,
      branch_id:this.cabang,
      name: this.nama,
      nip: this.nip,
      phone_number: this.phone,
      email: this.email,
      username: this.username,
      password: this.password,
    };
    if(this.idData){
      this.api.put('backoffice/manajemen-user/admin/'+this.idData, form)
      .subscribe((data) => {
        this.saving = data;
        if (this.saving.error == 0) {
          this.load = false;
          this.util.showToast(this.saving.message, 'success', 'bottom');
          this.api.dismissData('','confirm');

        } else {
          this.load = false;
          this.util.showToast(this.saving.message, 'danger', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
    }else{
    this.api.post('backoffice/manajemen-user/admin', form)
      .subscribe((data) => {
        this.saving = data;
        if (this.saving.error == 0) {
          this.load = false;
          this.util.showToast(this.saving.message, 'success', 'bottom');
          this.api.dismissData('','confirm');

        } else {
          this.load = false;
          this.util.showToast(this.saving.message, 'danger', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
    }
  }

  handleSelection(selectedValue: string, model: any) {
    if (model == 'cabang') {
      this.cabang = selectedValue;
    } else if (model == 'grup') {
      this.grup = selectedValue;
    } 
  }

  dismiss() {
    this.api.dismiss()
  }
}
