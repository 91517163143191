import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util/util.service';
import { KolektorPenagihanAddComponent } from '../kolektor-penagihan-add/kolektor-penagihan-add.component';
import { IzinPreviewComponent } from 'src/app/izin/izin-preview/izin-preview.component';
import { PreviewImageNotokenComponent } from 'src/app/shared/preview-image-notoken/preview-image-notoken.component';

@Component({
  selector: 'app-kolektor-penagihan-detail',
  templateUrl: './kolektor-penagihan-detail.component.html',
  styleUrls: ['./kolektor-penagihan-detail.component.scss'],
})
export class KolektorPenagihanDetailComponent implements OnInit {
  load: boolean = false;
  saving: any = {};
  idData: any;

  detData: any = {};
  det: any;
  tampilData: boolean = false;
 
  cabang: any = '';
  grup: any = '';
  nama: any = '';
  nip: any = '';
  jabatan: any = '';
  phone: any = '';
  email: any = '';
  username: any = '';
  password: any = '';

  usertype: any = '';

  selct: any = '';


  disabledInput: boolean = false;
  detList: any = {};

  id: any;
  ids: any;

  form: any;
  amor: any;

  riwPem: any = {};
  rw: any;

  riwayat_collector: any;
  penagihan_tertunda: any;
  riwayat_pemby: any;
  user: any = {};

  constructor(
    public api: ApiService,
    public util: UtilService,
    // public navParams: NavParams,
    public activatedRoute: ActivatedRoute,
    public modalController: ModalController

  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.no) {
        this.id = params.no;
        console.log('id', this.id)
        // this.fromPage = params.fromPage;
      }
    });

    try {
      this.user = JSON.parse(localStorage.getItem('user'));
    } catch (error) {
      this.user = [];
    }
   }

  ngOnInit() {
      this.detailData();
      this.riwayat();
  }

  pilih(p) {
    console.log(p);
    if (this.selct == '') {
      this.selct = p;
    } else if (this.selct == p) {
      // this.selct = '';
    } else {
      this.selct = p;
    }
    console.log('selct', this.selct);
  }

  getNamePhoto(path: any) {
    try {
      var photoName = path.substring(path.lastIndexOf("/") + 1, path.length);
      return photoName
    } catch (error) {
      console.log(error)
    }
  }

  async lihatI(id: any) {
    console.log(id)
    const modal = await this.modalController.create({
      component: PreviewImageNotokenComponent,
      cssClass: 'modalPreviewImage',
      componentProps: {
        id: id
      }
    });
    modal.onDidDismiss()
      .then((data: any) => {
        if (data.role === 'confirm') {
          // this.ionViewWillEnter();
        }
      });
    return await modal.present();
  }

  detailData(){
    this.api.get('backoffice/kolektor/penagihan/'+this.id)
      .subscribe((data) => {
        this.detData = data;
        if (this.detData.error == 0) {
          this.detList = this.detData.data;
          this.amor = this.detList.amortisasi;
          this.riwayat_collector = this.detList.riwayat_collector;
          this.riwayat_pemby = this.detList.riwayat_pemby;
          this.penagihan_tertunda = this.detList.penagihan_tertunda;
          console.log(this.amor)
        } else {
          this.load = false;
          this.util.showToast(this.detData.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  async tambah(id: any) {
    const modal = await this.modalController.create({
      component: KolektorPenagihanAddComponent,
      cssClass: 'modalForm',
      componentProps: {
        id: id
      }
    });
    modal.onDidDismiss()
      .then((data: any) => {
        if (data.role === 'confirm') {
          this.detailData();
        }
      });
    return await modal.present();
  }

  riwayat(){
    this.api.get('backoffice/kolektor/penagihan/riwayat-pemby/'+this.id+'/1')
      .subscribe((data) => {
        this.riwPem = data;
        if (this.riwPem.error == 0) {
          this.rw = this.riwPem.data;
        } else {
          this.load = false;
          this.util.showToast(this.riwPem.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  save(){
    this.load = true;
    var arrayData = [this.id];
    if(this.ids == ''){
      this.form = {
        contract_id: arrayData,
        assign_to: this.selct,
      };
    }else{
      this.form = {
        contract_id: JSON.parse(this.ids),
        assign_to: this.selct,
      };
    }
    this.api.post('backoffice/kolektor/penagihan/assignto', this.form)
      .subscribe((data) => {
        this.saving = data;
        if (this.saving.error == 0) {
          this.load = false;
          this.util.showToast(this.saving.message, 'success', 'bottom');
          this.api.dismissData('','confirm');
        } else {
          this.load = false;
          this.util.showToast(this.saving.message, 'danger', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  handleSelection(selectedValue: string, model: any) {
    if (model == 'cabang') {
      this.cabang = selectedValue;
    } else if (model == 'usertype') {
      this.usertype = selectedValue;
    } 
  }

  dismiss() {
    this.api.dismiss()
  }
}
