import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ActionSheetController, AlertController, LoadingController, MenuController, ModalController, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util/util.service';
// import { ComFilterLeadsComponent } from '../com-filter-leads/com-filter-leads.component';
// import { LeadsUpdateStatusPage } from '../leads-update-status/leads-update-status.page';
// import { UtilService } from '../services/util/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-group-edit',
  templateUrl: './user-group-edit.component.html',
  styleUrls: ['./user-group-edit.component.scss'],
})
export class UserGroupEditComponent implements OnInit {

  load: boolean = false;
  namagrup: any = '';

  
  manjang: boolean = false;
  baseUrl: any = '';
  provision_key: any = '';
  client_id: any = '';
  client_secret: any = '';
  grant_type: any = '';
  authUrl: any = '';

  chartt: any;
  info: string = "leads";
  accor:any = '';
  accorr:any = '';

  isIndeterminate:boolean;
  masterCheck:boolean;
  checkBoxList:any;

  page: any = 1;

  fractionSize;
  ionicForm: FormGroup;
  isSubmitted = false;
  nn: any = '';
  form: any = '';

  user: any;
  token: any;
  hasil: any;

  leadn: any = {};
  lnores: any = {};
  listData: any;
  aa: any;

  opsi:boolean=false;
  totalCekData: any;
  pageInfo: any = {};
  pageNation: any;

  viewType: any;

  filter: any = '';
  filterr: any = '';

  resAr: any;
  add: any = {};

  accordionExpanded = false;
  checkbox1 = false;
  checkbox2 = false;

  descgrup: any = '';
  copyfrom: any = '';

  dataTemp: any = {};
  dt: any;

  dataGetM: any = {};
  gm: any = {};
  gMenu: any;

  saving: any = {};
  id: any;

  constructor(
    public popoverController: PopoverController,
    public modalController: ModalController,
    public menuCtrl: MenuController,
    public route: Router,
    public api: ApiService,
    public util: UtilService,
    public activatedRoute: ActivatedRoute
  ) {
    this.baseUrl = environment.restURL.baseUrl;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.no) {
        this.id = params.no;
        console.log('id', this.id)
      }
    });
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user') || '');
    this.token = localStorage.getItem('usertoken') || '';
    // this.ionicForm = this.formBuilder.group({
    //   name: ['']
    // });
  }

  ionViewWillEnter() {
    this.page = 1;
    this.res();
    this.template();
  }

  nav(p: any) {
    this.route.navigate([p]);
  }

  save(){
    this.load = true;
    let form = {
      name: this.namagrup,
      desc: this.descgrup,
      menu: this.listData
    };
    console.log('Form', form);
    this.api.put('backoffice/group-menu/'+this.id, form)
      .subscribe((data) => {
        this.saving = data;
        if (this.saving.error == 0) {
          this.load = false;
          // this.dt = this.saving.data;
          this.nav('./grup-menu')
          this.util.showToast(this.saving.message, 'success', 'bottom');
        } else {
          this.load = false;
          this.util.showToast(this.saving.message, 'danger', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  template(){
    this.load = true;
    this.api.get('data/master/group-menu')
      .subscribe((data) => {
        this.dataTemp = data;
        if (this.dataTemp.error == 0) {
          this.load = false;
          this.dt = this.dataTemp.data;
          console.log('dt',this.dt);
        } else {
          this.load = false;
          this.util.showToast(this.dataTemp.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  getMenu(id: any){
    if(id !== ''){
    this.load = true;
    this.api.get('backoffice/group-menu/template-menu/'+id)
      .subscribe((data) => {
        this.dataGetM = data;
        if (this.dataGetM.error == 0) {
          this.load = false;
          this.gm = this.dataGetM.data;
          this.gMenu = this.gm.menu;
          this.listData = this.gMenu;
          console.log('gm',this.gm);
        } else {
          this.load = false;
          this.util.showToast(this.dataGetM.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
    }else{
      this.listData = this.leadn.data.menu;
      this.isIndeterminate = false;
      this.masterCheck = false;
    }
  }

  checkMaster() {
    setTimeout(()=>{
      this.listData.forEach(obj => {
        obj.selected = this.masterCheck;
      });
    });
  }

  toggleAccordion() {
    this.accordionExpanded = !this.accordionExpanded;
  }



  checkEventAction(){
    let childSelected = false;
    let actionsEnable = false; 
    this.listData.forEach(rootItem => {
      let childSelectedInRoot = false; 
      try {
        rootItem.children.forEach(childItem => {
          if (childItem.selected) {
            childSelectedInRoot = true;
            childSelected = true; 
            try {
              childItem.actions.forEach(actionsItem => {
                if (actionsItem.enable) {
                  childSelectedInRoot = true;
                  childSelected = true; 
                  actionsEnable = true; 
                }
              });
            } catch (error) {}
          }else{
            // childSelectedInRoot = true;
            childSelected = false; 
            try {
              childItem.actions.forEach(actionsItem => {
                if (actionsItem.enable) {
                  // childSelectedInRoot = true;
                  childSelected = false; 
                  actionsEnable = false; 
                }
              });
            } catch (error) {}
          }
        });
      } catch (error) {}
      rootItem.selected = childSelectedInRoot;
    });
    this.isIndeterminate = childSelected;
    console.log('listData', this.listData);
  }


  toggleCheck(menuItem: any, isParent: boolean, data: any) {
    if (isParent) {
        this.checkChildren(menuItem, menuItem.selected);
        this.toggleActions(menuItem, menuItem.selected,data);
        this.updateParentCheck(menuItem);
        this.checkParentAction(data,isParent);
    } else {
        this.toggleParentCheck(menuItem.parent);
        this.toggleChildren(menuItem, menuItem.selected);
        this.toggleActions(menuItem, menuItem.selected,data);
        var aa = data.children.find(item => item.selected === true)
        if(aa == undefined){
          this.checkParentAction(data,isParent);
        }
    }
}

toggleChild(menuItem: any, isParent: boolean, data: any) {
  console.log(menuItem+' chld '+isParent+' chld '+data)
  if (isParent) {
    console.log('1')
      this.checkChildren(menuItem, menuItem.selected);
      this.toggleActions(menuItem, menuItem.selected, data);
      this.checkParentAction(data,isParent);
  } else {
    console.log('2')
      this.toggleParentCheck(menuItem.parent);
      this.toggleChildren(menuItem, menuItem.selected);
      this.toggleActions(menuItem, menuItem.selected, data);
      var aa = data.children.find(item => item.selected === true)
      if(aa == undefined){
        this.checkParentAction(data,isParent);
      }
  }
}

toggleActions(menuItem: any, selected: boolean, data: any) {
  console.log(' action '+selected)
  if (menuItem.children) {
      menuItem.children.forEach((child: any) => {
          child.selected = selected;
          if (child.actions) {
              child.actions.forEach((action: any) => {
                  action.enable = selected;
              }); 
          }
      });
  }
  // Jika item menu memiliki actions, toggle enable untuk setiap action
  if (menuItem.actions) {
      menuItem.actions.forEach((action: any) => {
          action.enable = selected;
      });
  }
  // Update parent check jika ada
  if (menuItem.parent) {
      this.updateParentCheck(menuItem.parent);
  }
}

checkParentAction(data: any, isParent: boolean) {
  // console.log(data)
  if (data.selected) {
    data.selected = isParent;
  }else{
    data.selected = isParent;

  }
}

checkParentCheck(childItem: any) {
  if (childItem.parent) {
      const allChildrenSelected = childItem.parent.children.every((child: any) => child.selected);
      childItem.parent.selected = allChildrenSelected;
      this.checkParentCheck(childItem.parent);
  }
}

toggleChildren(menuItem: any, selected: boolean) {
    if (menuItem.children) {
        menuItem.children.forEach(child => {
            child.selected = selected;
            this.checkActions(child, selected);
            this.toggleChildren(child, selected);
        });
    }
}
  
  checkChildren(menuItem: any, selected: boolean) {
    if (menuItem.children) {
      menuItem.children.forEach(child => {
        child.selected = selected;
        this.checkActions(child, selected);
        this.checkChildren(child, selected);
      });
    }
  }
  
  checkActions(menuItem: any, selected: boolean) {
    if (menuItem.actions) {
      menuItem.actions.forEach(action => {
        action.enable = selected;
      });
    }
  }
  
  updateParentCheck(menuItem: any) {
    if (menuItem.parent) {
      const allChildrenChecked = menuItem.parent.children.every(child => child.selected);
      const allActionsChecked = menuItem.parent.children.every(child => {
        if (child.actions) {
          return child.actions.every(action => action.enable);
        }
        return true;
      });
      menuItem.parent.selected = allChildrenChecked && allActionsChecked;
      this.updateParentCheck(menuItem.parent);
    }
  }
  
  toggleParentCheck(parentItem: any) {
    if (parentItem) {
      parentItem.selected = parentItem.children.every(child => child.selected);
      this.toggleParentCheck(parentItem.parent);
    }
  }
  
  checkEvent() {
    const totalItems = this.listData.length;
    let checked = 0;
    this.listData.map(obj => {
      if (obj.selected) checked++;
    });
    if (checked > 0 && checked < totalItems) {
      //Check Satu
      this.isIndeterminate = true;
      this.masterCheck = false;
      this.aa = this.listData.filter((value, index) => {
        return value.selected
      });
      this.opsi = true;
    } else if (checked == totalItems) {
      //Check Semua
      this.masterCheck = true;
      this.isIndeterminate = false;
      this.aa = this.listData.filter((value, index) => {
        return value.selected
      });
      this.opsi = true;
    } else {
      //Uncheck
      this.isIndeterminate = false;
      this.masterCheck = false;
      this.aa = '';
      this.opsi = false;
    }

    try {
      this.listData.forEach(rootItem => {
        try {
          rootItem.children.forEach(childItem => {
            childItem.selected = rootItem.selected;
            try {
              childItem.actions.forEach(actionItem => {
                actionItem.enable = rootItem.selected;
              });
            } catch (error) {}
          });
        } catch (error) {}
      });
    } catch (error) {}
    
    console.log('aa',this.aa);
    console.log('opsi',this.opsi);
    this.totalCekData = checked;
    console.log('listData',this.listData)
  }


  res() {
    console.log('pageNotResponse',this.page);
    let a = {
      name: this.nn,
    }
    this.form = JSON.stringify({
      q: a,
      page: this.page,
    });
    console.log('FORM', this.form);
    this.load = true;
    // this.api.get('backoffice/group-menu/template-menu'+this.filterr)
    this.api.get('backoffice/group-menu/list/'+this.id)
      .subscribe((data) => {
        this.leadn = data;
        if (this.leadn.error == 0) {
          this.load = false;
          this.lnores = this.leadn.data;
          this.listData = this.leadn.data.menu;
          this.descgrup = this.leadn.data.desc;
          this.namagrup = this.leadn.data.name;
          console.log('lnores',this.lnores);

        } else {
          this.load = false;
          this.util.showToast(this.leadn.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  acc(aa: any){
    console.log('val', aa);
    var bb = aa.toString();
    if(this.accor == ''){
      this.accor = bb;
      console.log('1')
    } else if (this.accor == bb){
      this.accor = '';
      console.log('2')
    } else {
      this.accor = bb;
      console.log('3')
    }
    console.log('accor', this.accor);
  }
 
  openOpsi(){
    if(this.opsi == false){
      this.opsi = true;
    } else {
      this.opsi = false;
    }
    console.log(this.opsi);
  }

  dismiss(){
    this.opsi = false;
  }

  det(p){
    let navigationExtras: NavigationExtras = {
       queryParams: {
         no: p,
         fromPage:'leads'
       }
     };
     this.route.navigate(['./leads-detail'], navigationExtras);
   }


  submit(){
  }




}
