import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SwiperModule } from 'swiper/angular';
import { NgChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import {NgxPaginationModule} from 'ngx-pagination';
import { SharedModule } from './shared/shared.module';
// import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
// import { File } from '@awesome-cordova-plugins/file/ngx';
import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
// import { CKEditorModule } from 'ng2-ckeditor';
// import { EditorModule } from '@tinymce/tinymce-angular';
// import { QuillModule } from 'ngx-quill';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { ClassicEditor } from '@ckeditor/ckeditor5-build-classic';
// import { NgxQuillModule } from '@dimpu/ngx-quill';
// import { NgxEditorModule } from 'ngx-editor';
// import {MatCheckboxModule} from '@angular/material/checkbox';
// import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    declarations: [
        AppComponent,
        // HeaderComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        SwiperModule,
        HttpClientModule,
        IonCustomScrollbarModule,
        FormsModule,
        NgChartsModule,
        NgxPaginationModule,
        BrowserAnimationsModule,
        // MatCheckboxModule,
        // MatExpansionModule,
        // NgxEditorModule
        // EditorModule
        // CKEditorModule,
        // QuillModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        // File,
        // FileTransfer,
        // FileTransferObject,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
