import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-preview-image-notoken',
  templateUrl: './preview-image-notoken.component.html',
  styleUrls: ['./preview-image-notoken.component.scss'],
})
export class PreviewImageNotokenComponent implements OnInit {

  img: any;
  constructor(
    // public api: ApiService,
    // public util: UtilService,
    public navParams: NavParams,
    // public dom: DomSanitizer,

  ) {
    this.img = this.navParams.data.id;
    // this.transform(this.id)
    // console.log('id',this.id);
   }

  ngOnInit() {}

}
