import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserGroupAddComponent } from './user-group/user-group-add/user-group-add.component';
import { UserGroupEditComponent } from './user-group/user-group-edit/user-group-edit.component';
import { UserAdminAddComponent } from './user-admin/user-admin-add/user-admin-add.component';
import { UserCabangAddComponent } from './user-cabang/user-cabang-add/user-cabang-add.component';
import { KolektorPenagihanDetailComponent } from './kolektor/kolektor-penagihan/kolektor-penagihan-detail/kolektor-penagihan-detail.component';
import { MemorandumDetailComponent } from './credit-analys/memorandum/memorandum-detail/memorandum-detail.component';
import { ProdukAddComponent } from './produk/produk-add/produk-add.component';
import { ProdukEditComponent } from './produk/produk-edit/produk-edit.component';
import { MemorandumAppDetailComponent } from './credit-analys/approval-memorandum/memorandum-app-detail/memorandum-app-detail.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'leads',
    loadChildren: () => import('./leads/leads.module').then( m => m.LeadsPageModule)
  },
  {
    path: 'prospect',
    loadChildren: () => import('./prospect/prospect.module').then( m => m.ProspectPageModule)
  },
  {
    path: 'leads-update-status',
    loadChildren: () => import('./leads-update-status/leads-update-status.module').then( m => m.LeadsUpdateStatusPageModule)
  },
  {
    path: 'leads-detail',
    loadChildren: () => import('./leads-detail/leads-detail.module').then( m => m.LeadsDetailPageModule)
  },
  {
    path: 'assignment-partner',
    loadChildren: () => import('./assignment-partner/assignment-partner.module').then( m => m.AssignmentPartnerPageModule)
  },
  {
    path: 'pengajuan-slik',
    loadChildren: () => import('./pengajuan-slik/pengajuan-slik.module').then( m => m.PengajuanSlikPageModule)
  },
  {
    path: 'arsip',
    loadChildren: () => import('./arsip/arsip.module').then( m => m.ArsipPageModule)
  },
  {
    path: 'list-partner',
    loadChildren: () => import('./list-partner/list-partner.module').then( m => m.ListPartnerPageModule)
  },
  {
    path: 'daftar-kehadiran',
    loadChildren: () => import('./kehadiran/kehadiran.module').then( m => m.KehadiranPageModule)
  },
  {
    path: 'daftar-pengajuan-izin',
    loadChildren: () => import('./izin/izin.module').then( m => m.IzinPageModule)
  },
  {
    path: 'explore',
    loadChildren: () => import('./explore/explore.module').then( m => m.ExplorePageModule)
  },
  {
    path: 'listing-saya',
    loadChildren: () => import('./listing-saya/listing-saya.module').then( m => m.ListingSayaPageModule)
  },
  {
    path: 'assignment-partner-add',
    loadChildren: () => import('./assignment-partner-add/assignment-partner-add.module').then( m => m.AssignmentPartnerAddPageModule)
  },
  {
    path: 'assignment-detail',
    loadChildren: () => import('./assignment-detail/assignment-detail.module').then( m => m.AssignmentDetailPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'ganti-sales',
    loadChildren: () => import('./ganti-sales/ganti-sales.module').then( m => m.GantiSalesPageModule)
  },
  {
    path: 'kehadiran-detail',
    loadChildren: () => import('./kehadiran-detail/kehadiran-detail.module').then( m => m.KehadiranDetailPageModule)
  },
  {
    path: 'kolektor-tagihan',
    loadChildren: () => import('./kolektor-tagihan/kolektor-tagihan.module').then( m => m.KolektorTagihanPageModule)
  },
  {
    path: 'kolektor-permohonan',
    loadChildren: () => import('./kolektor-permohonan/kolektor-permohonan.module').then( m => m.KolektorPermohonanPageModule)
  },
  {
    path: 'kolektor-visit',
    loadChildren: () => import('./kolektor-visit/kolektor-visit.module').then( m => m.KolektorVisitPageModule)
  },
  {
    path: 'kolektor-assignment',
    loadChildren: () => import('./kolektor-assignment/kolektor-assignment.module').then( m => m.KolektorAssignmentPageModule)
  },
  {
    path: 'kolektor-hasil',
    loadChildren: () => import('./kolektor-hasil/kolektor-hasil.module').then( m => m.KolektorHasilPageModule)
  },
  {
    path: 'kolektor-janji',
    loadChildren: () => import('./kolektor-janji/kolektor-janji.module').then( m => m.KolektorJanjiPageModule)
  },
  {
    path: 'surveyor-approval-update',
    loadChildren: () => import('./surveyor-approval-update/surveyor-approval-update.module').then( m => m.SurveyorApprovalUpdatePageModule)
  },
  {
    path: 'leads-detail-penjamin-modal',
    loadChildren: () => import('./leads-detail-penjamin-modal/leads-detail-penjamin-modal.module').then( m => m.LeadsDetailPenjaminModalPageModule)
  },
  {
    path: 'surveyor-schedule-list',
    loadChildren: () => import('./surveyor-schedule-list/surveyor-schedule-list.module').then( m => m.SurveyorScheduleListPageModule)
  },
  {
    path: 'surveyor-schedule-detail',
    loadChildren: () => import('./surveyor-schedule-detail/surveyor-schedule-detail.module').then( m => m.SurveyorScheduleDetailPageModule)
  },
  {
    path: 'surveyor-schedule-form',
    loadChildren: () => import('./surveyor-schedule-form/surveyor-schedule-form.module').then( m => m.SurveyorScheduleFormPageModule)
  },



  // ROUTING BARU
  {
    path: 'daftar-leads',
    loadChildren: () => import('./leads/leads.module').then( m => m.LeadsPageModule)
  },
  {
    path: 'pengajuan-slik',
    loadChildren: () => import('./pengajuan-slik/pengajuan-slik.module').then( m => m.PengajuanSlikPageModule)
  },
  {
    path: 'grup-menu',
    loadChildren: () => import('./user-group/user-group.module').then( m => m.UserGroupPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./user-admin/user-admin.module').then( m => m.UserAdminPageModule)
  },
  {
    path: 'user-group-add', component: UserGroupAddComponent
  },
  {
    path: 'user-group-edit', component: UserGroupEditComponent
  },
  {
    path: 'user-admin-add', component: UserAdminAddComponent
  },
  {
    path: 'user-cabang-add', component: UserCabangAddComponent
  },
  {
    path: 'lapangan',
    loadChildren: () => import('./user-lapangan/user-lapangan.module').then( m => m.UserLapanganPageModule)
  },
  {
    path: 'cabang',
    loadChildren: () => import('./user-cabang/user-cabang.module').then( m => m.UserCabangPageModule)
  },
  {
    path: 'rekening',
    loadChildren: () => import('./rekening/rekening.module').then( m => m.RekeningPageModule)
  },
  {
    path: 'daftar-penagihan',
    loadChildren: () => import('./kolektor/kolektor-penagihan/kolektor-penagihan.module').then( m => m.KolektorPenagihanPageModule)
  },
  {
    path: 'kolektor-penagihan-detail', component: KolektorPenagihanDetailComponent
  },
  {
    path: 'kontrak-lunas',
    loadChildren: () => import('./kolektor/kolektor-kontrak-lunas/kolektor-kontrak-lunas.module').then( m => m.KolektorKontrakLunasPageModule)
  },
  {
    path: 'angsuran-tertagih',
    loadChildren: () => import('./kolektor/kolektor-angsuran-tertagih/kolektor-angsuran-tertagih.module').then( m => m.KolektorAngsuranTertagihPageModule)
  },
  {
    path: 'memorandum',
    loadChildren: () => import('./credit-analys/memorandum/memorandum.module').then( m => m.MemorandumPageModule)
  },
  {
    path: 'memorandum-detail', component: MemorandumDetailComponent
  },
  {
    path: 'produk',
    loadChildren: () => import('./produk/produk.module').then( m => m.ProdukPageModule)
  },
  {
    path: 'produk-add', component: ProdukAddComponent
  },
  {
    path: 'produk-edit', component: ProdukEditComponent
  },
  {
    path: 'memorandum-detail', component: MemorandumDetailComponent
  },
  {
    path: 'memorandum-app-detail', component: MemorandumAppDetailComponent
  },
  {
    path: 'approval-memorandum',
    loadChildren: () => import('./credit-analys/approval-memorandum/approval-memorandum.module').then( m => m.ApprovalMemorandumPageModule)
  },
  {
    path: 'permohonan',
    loadChildren: () => import('./kolektor/permohonan/permohonan.module').then( m => m.PermohonanPageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
