import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from './services/util/util.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {


  menU: any = 1;
  subMenu: any = 0;
  sideMenu: boolean = true;

  appPages = [
    {
      title: 'Dashboard',
      url: '/home',
      icon: 'grid',
      position: 'top',
      imagebefore: 'icon-dashboard-blue.svg',
      imageafter: 'icon-dashboard.svg'
    },
    {
      title: 'Leads',
      url: '/leads',
      icon: 'film',
      position: 'top',
      imagebefore: 'icon-leads-blue.svg',
      imageafter: 'icon-leads.svg'
    },
    {
      title: 'Prospect',
      url: '/funding',
      icon: 'settings',
      position: 'top',
      imagebefore: 'icon-dashboard-blue.svg',
      imageafter: 'icon-dashboard.svg'
    },
    {
      title: 'Partner',
      url: '',
      icon: 'film',
      position: 'top',
      imagebefore: 'icon-dashboard-blue.svg',
      imageafter: 'icon-dashboard.svg'
    },
    {
      title: 'Marketplace',
      url: '',
      icon: 'film',
      position: 'top',
      imagebefore: 'icon-dashboard-blue.svg',
      imageafter: 'icon-dashboard.svg'
    },
    {
      title: 'User',
      url: '/user',
      icon: 'film',
      position: 'bottom',
      imagebefore: 'icon-dashboard-blue.svg',
      imageafter: 'icon-dashboard.svg'
    },
    {
      title: 'Setting',
      url: '/setting',
      icon: 'film',
      position: 'bottom',
      imagebefore: 'icon-dashboard-blue.svg',
      imageafter: 'icon-dashboard.svg'
    },
    {
      title: 'Logout',
      url: '/logout',
      icon: 'film',
      position: 'bottom',
      imagebefore: 'icon-dashboard-blue.svg',
      imageafter: 'icon-dashboard.svg'
    },
  ];

  appPagesBottom = [
    {
      title: 'User',
      url: '/user',
      icon: 'film',
      position: 'bottom',
      imagebefore: 'icon-dashboard-blue.svg',
      imageafter: 'icon-dashboard.svg'
    },
    {
      title: 'Setting',
      url: '/setting',
      icon: 'film',
      position: 'bottom',
      imagebefore: 'icon-dashboard-blue.svg',
      imageafter: 'icon-dashboard.svg'
    },
    {
      title: 'Logout',
      url: '/logout',
      icon: 'film',
      position: 'bottom',
      imagebefore: 'icon-dashboard-blue.svg',
      imageafter: 'icon-dashboard.svg'
    },
  ];
  // public appPages = [
  //   { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
  //   { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
  //   { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
  //   { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
  //   { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
  //   { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  // ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];


  usertoken: any;
  user: any;

  menu: any;

  hasilSlug: any;

  constructor(
    public router: Router,
    public util: UtilService,

  ) {
    this.usertoken = localStorage.getItem('usertoken') || '';
    // console.log('ut',this.usertoken);
    if (this.usertoken) {
      this.router.navigateByUrl('/home');
      this.user = JSON.parse(localStorage.getItem('user') || '');
      // console.log('user', this.user);
      this.menu = this.user.access_menu;
    } else {
      this.router.navigateByUrl('/login');
    }
    // this.router.navigateByUrl('');
  }

  ngOnInit() {

  }

  fungsilogout() {
    this.util.showToast('Anda Berhasil Keluar', 'success', 'bottom');
    localStorage.removeItem('usertoken');
    window.location.reload();
    this.router.navigate(['./login']);
  }

  selectedIndex(nama: any) {
    var url = this.generateSlug(nama)
    console.log(url)
    if (this.menU == '') {
      this.menU = nama;
    } else if (this.menU == nama) {
      this.menU = '';
    } else {
      this.menU = nama;
    }
    this.router.navigate([url]);
  }

  generateSlug(inputText: any) {
    let filteredText = inputText.replace(/[^a-zA-Z0-9\s]/g, '');
    let textWithoutSpaces = filteredText.replace(/\s+/g, '-');
    let lowercaseText = '/' + textWithoutSpaces.toLowerCase();
    return lowercaseText;
    // console.log(lowercaseText)

  }

  buka(nama: any) {
    if (this.subMenu == 0) {
      this.subMenu = nama;
      this.sideMenu = true;
    } else if (this.subMenu == nama) {
      this.subMenu = 0;
    } else {
      this.subMenu = nama;
    }
    console.log('submenu', this.subMenu);
  }

  minimize() {
    if (this.sideMenu == true) {
      this.sideMenu = false;
      this.subMenu = 0;
    } else {
      this.sideMenu = true;
    }
    console.log('submenu', this.subMenu);
  }



}
