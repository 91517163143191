import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-memorandum-rekomendasi',
  templateUrl: './memorandum-rekomendasi.component.html',
  styleUrls: ['./memorandum-rekomendasi.component.scss'],
})
export class MemorandumRekomendasiComponent implements OnInit {

  
  load: boolean = false;
  saving: any = {};
  idData: any;

  detData: any = {};
  det: any;
  tampilData: boolean = false;
 
  cabang: any = '';
  grup: any = '';
  nama: any = '';
  nip: any = '';
  jabatan: any = '';
  phone: any = '';
  email: any = '';
  username: any = '';
  password: any = '';

  usertype: any = '';

  disabledInput: boolean = false;
  datada: any = {};

  ringkasan: any = {};
  rekomendasi_surveyor: any = {};
  pengajuan: any = {};

  rekomendasi: any = {};

  keterangan: any = '';
tenor: any = '';
submission_loan: any = '';

flg_perubahan_akta: boolean = false;
flgPerubahanAkta: any = 'N';

leadn: any = {};
lnores: any;
prod: any = {};

  constructor(
    public api: ApiService,
    public util: UtilService,
    public navParams: NavParams
  ) {
    this.datada = JSON.parse(this.navParams.data.id)
    console.log('datada',this.datada)
    this.pengajuan = this.datada.pengajuan || '';
    this.ringkasan = this.datada.ringkasan;
    this.rekomendasi_surveyor = this.ringkasan.rekomendasi_surveyor;
    console.log('pengajuan',this.pengajuan)
    console.log('ringkasan',this.ringkasan)
    console.log('rekomendasi_surveyor',this.rekomendasi_surveyor)
    this.idData = this.datada.lead_id;
    this.prod = this.pengajuan.product;
    console.log('prod',this.prod)
    console.log('prodid',this.prod.id)

   }

  ngOnInit() {
    // this.idData = this.navParams.data.id;
    // console.log(this.idData);
    // if(this.idData){
    //   this.disabledInput = true;
    //   this.detailData()
    // }else{
    //   this.disabledInput = false;
    //   this.tampilData = true;
    // }
    this.res();
  }

  res() {
    this.load = true;
    this.api.get('data/master/tenor-product-client/'+this.prod.id)
      .subscribe((data) => {
        this.leadn = data;
        if (this.leadn.error == 0) {
          this.load = false;
            this.lnores = this.leadn.data;
          
        } else {
          this.load = false;
          this.util.showToast(this.leadn.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  pilihSesuaiAkta(event) {
    console.log(event.detail.checked);
    if (event.detail.checked == true) {
      this.flg_perubahan_akta = true;
      this.flgPerubahanAkta = 'Y';
      this.submission_loan = this.pengajuan.submission_loan;
      this.tenor = this.pengajuan.submission_tenor_id;
      this.disabledInput = true;
    } else {
      this.flg_perubahan_akta = false;
      this.flgPerubahanAkta = 'N';
      this.submission_loan = '';
      this.tenor = '';
      this.disabledInput = false;
    }
  }

  detailData(){
    this.api.get('backoffice/manajemen-user/lapangan/list/'+this.idData)
      .subscribe((data) => {
        this.detData = data;
        if (this.detData.error == 0) {
          this.cabang = this.detData.data.branch_id;
          this.usertype = this.detData.data.jabatan;
          this.nip = this.detData.data.nik;

          this.grup = this.detData.data.group;
          this.nama = this.detData.data.name;
          this.phone = this.detData.data.phone_number;
          this.email = this.detData.data.email;
          this.username = this.detData.data.username;
          this.tampilData = true;
          setTimeout(() => {
            this.load = false;
            }, 1000);
        } else {
          this.load = false;
          this.util.showToast(this.detData.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  save(){
    this.load = true;
    let form = {
      status: this.rekomendasi,
      is_sesuai_pengajuan: this.flgPerubahanAkta,
      submission_loan:this.submission_loan,
      submission_tenor_id: this.tenor,
      keterangan: this.keterangan
    };
    if(this.idData){
      this.api.post('backoffice/credit-analyst/rekomendasi/'+this.idData, form)
      .subscribe((data) => {
        this.saving = data;
        if (this.saving.error == 0) {
          this.load = false;
          this.util.showToast(this.saving.message, 'success', 'bottom');
          this.api.dismissData('','confirm');

        } else {
          this.load = false;
          this.api.dismissData('','confirm');
          this.util.showToast(this.saving.message, 'danger', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
    }else{
    this.api.post('backoffice/manajemen-user/lapangan', form)
      .subscribe((data) => {
        this.saving = data;
        if (this.saving.error == 0) {
          this.load = false;
          this.util.showToast(this.saving.message, 'success', 'bottom');
          this.api.dismissData('','confirm');

        } else {
          this.api.dismissData('','confirm');
          this.load = false;
          this.util.showToast(this.saving.message, 'danger', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
    }
  }

  handleSelection(selectedValue: string, model: any) {
    if (model == 'cabang') {
      this.cabang = selectedValue;
    } else if (model == 'usertype') {
      this.usertype = selectedValue;
    } 
  }

  dismiss() {
    this.api.dismiss()
  }
}
