export const environment = {
  production: false,
  restURL: {
    baseUrl: 'https://apidev.jetapps.id/v001/',
    authUrl: 'https://apidev.jetapps.id/v001/',
    // baseUrl: 'https://api.jetapps.id/v001/',
    // authUrl: 'https://api.jetapps.id/v001/',
    provision_key: 'QA2euZP3O214ZdYbXpmS0IOKfIPEZPy7',
    client_id: '0jubsFJCBOqq0aGpW30NEXB45s0jzes3',
    client_secret: '6btZKFb2u5tHIs1vheQbXqPlBRvJRDu8',
    grant_type: 'password'
  },
};