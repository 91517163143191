import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-memorandum-approve-reject',
  templateUrl: './memorandum-approve-reject.component.html',
  styleUrls: ['./memorandum-approve-reject.component.scss'],
})
export class MemorandumApproveRejectComponent implements OnInit {

  
  load: boolean = false;
  saving: any = {};
  idData: any;

  detData: any = {};
  det: any;
  tampilData: boolean = false;
 
  cabang: any = '';
  grup: any = '';
  nama: any = '';
  nip: any = '';
  jabatan: any = '';
  phone: any = '';
  email: any = '';
  username: any = '';
  password: any = '';

  usertype: any = '';

  disabledInput: boolean = false;
  datada: any = {};

  ringkasan: any = {};
  rekomendasi_surveyor: any = {};
  pengajuan: any = {};

  rekomendasi: any = {};

  keterangan: any = '';
tenor: any = '';
submission_loan: any = '';

flg_perubahan_akta: boolean = false;
flgPerubahanAkta: any = 'N';
st: any;
credit_analyst: any = {};

selct: any = 'Manual';
form: any = {};

desc: any = '';
asjiw: any = '';
asjam: any = '';

asji: any = '';

  constructor(
    public api: ApiService,
    public util: UtilService,
    public navParams: NavParams
  ) {
    this.datada = JSON.parse(this.navParams.data.id)
    console.log(this.datada)
    this.pengajuan = this.datada.pengajuan || '';
    this.ringkasan = this.datada.ringkasan;
    this.rekomendasi_surveyor = this.ringkasan.rekomendasi.surveyor;
    this.credit_analyst = this.ringkasan.rekomendasi.credit_analyst;

    console.log(this.ringkasan)
    console.log(this.rekomendasi_surveyor)
    this.idData = this.datada.lead_id;
    this.st = this.navParams.data.st;
   }

  ngOnInit() {
    // this.idData = this.navParams.data.id;
    // console.log(this.idData);
    // if(this.idData){
    //   this.disabledInput = true;
      this.detailData()
    // }else{
    //   this.disabledInput = false;
    //   this.tampilData = true;
    // }
  }

  pilih(p) {
    console.log(p);
    if (this.selct == '') {
      this.selct = p;
    } else if (this.selct == p) {
      // this.selct = '';
    } else {
      this.selct = p;
    }
    console.log('selct', this.selct);
  }

  pilihSesuaiAkta(event) {
    console.log(event.detail.checked);
    if (event.detail.checked == true) {
      this.flg_perubahan_akta = true;
      this.flgPerubahanAkta = 'Y';
      this.submission_loan = this.pengajuan.submission_loan;
      this.tenor = this.pengajuan.submission_tenor_id;
      this.disabledInput = true;
    } else {
      this.flg_perubahan_akta = false;
      this.flgPerubahanAkta = 'N';
      this.submission_loan = '';
      this.tenor = '';
      this.disabledInput = false;
    }
  }

  detailData(){
    this.api.get('backoffice/credit-analyst/app-memo/'+this.idData)
      .subscribe((data) => {
        this.detData = data;
        if (this.detData.error == 0) {
          console.log('detData', this.detData);

          this.tampilData = true;
          setTimeout(() => {
            this.load = false;
            }, 1000);
        } else {
          this.load = false;
          this.util.showToast(this.detData.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  save(){
    this.load = true;
    if(this.st == 'ap'){
      this.form = {
        status: 'PROSES',
        methode_pencairan: this.selct,
        nilai_asuransi_jaminan:this.asjam,
        nilai_asuransi_jiwa: this.asjiw
      };
    }else{
      this.form = {
        status: 'TOLAK',
        keterangan: this.desc,
        nilai_asuransi_jaminan:this.asjam,
        nilai_asuransi_jiwa: this.asjiw
      };
    }
    
    // if(this.idData){
      this.api.post('backoffice/credit-analyst/app-memo/'+this.idData, this.form)
      .subscribe((data) => {
        this.saving = data;
        if (this.saving.error == 0) {
          this.load = false;
          this.util.showToast(this.saving.message, 'success', 'bottom');
          this.api.dismissData('','confirm');

        } else {
          this.load = false;
          this.util.showToast(this.saving.message, 'danger', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
    // }else{
    // this.api.post('backoffice/manajemen-user/lapangan', form)
    //   .subscribe((data) => {
    //     this.saving = data;
    //     if (this.saving.error == 0) {
    //       this.load = false;
    //       this.util.showToast(this.saving.message, 'success', 'bottom');
    //       this.api.dismissData('','confirm');

    //     } else {
    //       this.load = false;
    //       this.util.showToast(this.saving.message, 'danger', 'bottom');
    //     }
    //   }, (error) => {
    //     this.load = false;
    //     console.log('error', error);
    //     this.api.errorCond(error)
    //   });
    // }
  }

  handleSelection(selectedValue: string, model: any) {
    if (model == 'cabang') {
      this.cabang = selectedValue;
    } else if (model == 'usertype') {
      this.usertype = selectedValue;
    } 
  }

  dismiss() {
    this.api.dismiss()
  }
}
