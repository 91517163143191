import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util/util.service';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// declare const CKEDITOR: any;


@Component({
  selector: 'app-produk-add',
  templateUrl: './produk-add.component.html',
  styleUrls: ['./produk-add.component.scss'],
})
export class ProdukAddComponent implements OnInit {

  load: boolean = false;
  saving: any = {};
  idData: any;

  detData: any = {};
  det: any;
  tampilData: boolean = false;

  cabang: any = '';
  grup: any = '';
  nama: any = '';
  nip: any = '';
  jabatan: any = '';
  phone: any = '';
  email: any = '';
  username: any = '';
  password: any = '';

  disabledInput: boolean = false;

  pilihJam: any = '';
  pemilikJam: any = '';
  form: any = {};
  dataChar: any;
  listBillx: any;

  submission_loan_min: any = '';
  submission_loan_max: any = '';
  interest_rate_type: any = 'fixed';

  tenors: any;
  tags: string[] = ['12M', '18M', '24M'];
  newTag: string = '';

  tenorsx: any;
  tagsx: string[] = ['ADM'];
  newTagx: string = '';

  pj: any;
  peJam: string[] = ['MS'];
  newPj: string = '';

  interest_rate: any = '';

  minTenor: any = '';
  maxTenor: any = '';
  payment_type_id: any = '';
  ketentuan: any = '';
  desc: any = '';

  prv: any = {};
  adm: any = {};
  crc: any = {};
  ajm: any = {};
  ajw: any = {};

  a: string;
  b: string;
  c: string;
  d: string;
  e: string;
  f: string;

  gg: string;
  hh: string;
  ii: string;
  jj: string;
  kk: string;

  admtype: any = 'free';
  admtypepercent: any = 'PERCENTAGE';
  admtypetambahan: any = 'NOMINAL';
  admtypefix: any = '';
  admtypeMin: any = 0;
  admtypeMax: any = '';
  admtypeValue: any = '';
  admTenor: any = '';
  admtambahan: any = '';
  dataAdm: any;
  admtypeMinSepa: any = 0;
  admtypeMaxSepa: any = '';
  admtypeValueSepa: any = '';
  admtambahanSepa: any = '';


  admjenis: any = 'Standard';

  prvtype: any = 'free';
  prvtypepercent: any = 'PERCENTAGE';
  prvtypefix: any = '';
  prvtypeMin: any = '';
  prvtypeMax: any = '';
  prvtypeValue: any = '';
  prvTenor: any = '';
  prvtypetambahan: any = 'NOMINAL';
  prvtambahan: any = '';
  dataPrv: any;
  prvtypeMinSepa: any = 0;
  prvtypeMaxSepa: any = '';
  prvtypeValueSepa: any = '';
  prvtambahanSepa: any = '';

  crctype: any = 'free';
  crctypepercent: any = 'PERCENTAGE';
  crctypefix: any = '';
  crctypeMin: any = '';
  crctypeMax: any = '';
  crctypeValue: any = '';
  crctypetambahan: any = 'NOMINAL';
  crcTenor: any = '';
  crctambahan: any = '';
  dataCrc: any;
  crctypeMinSepa: any = 0;
  crctypeMaxSepa: any = '';
  crctypeValueSepa: any = '';
  crctambahanSepa: any = '';

  ajmtype: any = 'free';
  ajmtypepercent: any = 'PERCENTAGE';
  ajmtypefix: any = '';
  ajmtypeMin: any = '';
  ajmtypeMax: any = '';
  ajmtypeValue: any = '';
  ajmTenor: any = '';
  ajmtypetambahan: any = 'NOMINAL';
  ajmtambahan: any = '';
  dataAjm: any;

  ajwtype: any = 'free';
  ajwtypepercent: any = 'PERCENTAGE';
  ajwtypefix: any = '';
  ajwtypeMin: any = '';
  ajwtypeMax: any = '';
  ajwtypeValue: any = '';
  ajwTenor: any = '';
  ajwtypetambahan: any = 'NOMINAL';
  ajwtambahan: any = '';
  dataAjw: any;

  dataTnr: any;

  limitLtv: boolean = true;
  disabledInputLTV: boolean = false;
  maxltv: any = '';

  prvx: any = {};
  admx: any = {};
  crcx: any = {};
  ajmx: any = {};
  ajwx: any = {};

  mergedArray: any;

  ten: any = {};
  tn: any;

  jenSer: any = {}
  js: any;

  kepSer: any = {};
  ks: any;

  payTip: any = {};
  pt: any;

  cosMan: any = {};
  cm: any;

  warnText: boolean = false;

  warnTextprv: any = {};
  warnTextadm: any = {};
  warnTextcrc: any = {};
  warnTextajm: any = {};
  warnTextajw: any = {};

  warnTextTenor: boolean = false;

  warnTextadm2: boolean = false;
  warnTextprv2: boolean = false;
  warnTextcrc2: boolean = false;
  warnTextajm2: boolean = false;
  warnTextajw2: boolean = false;

  // public Editor = ClassicEditor;
  // editorData: string = '<p>Isi konten default</p>';
  tnx: any;

  stdTenor: any = '';
  stdBiaya: any = '';
  stdBiayaSepa: any = '';
  stdtypepercent: any = 'NOMINAL';
  dataStd: any;


  tbKondisi: any = '';
  tbPlafon: any = '';
  tbPlafonSepa: any = '';
  tbPlafontypepercent: any = 'NOMINAL';

  tbTambahan: any = '';
  tbTambahanSepa: any = '';
  tbTambahantypepercent: any = 'PERCENTAGE';

  dataTb: any;


  constructor(
    public api: ApiService,
    public util: UtilService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    // public navParams: NavParams
  ) {

  }


  ngOnInit() {
    localStorage.removeItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj');
    this.dataChar = '';

    localStorage.removeItem('89234yug387g23e283dg823dg2837t238d72t37623CRC');
    this.dataCrc = '';

    localStorage.removeItem('89234yug387g23e283dg823dg2837t238d72t37623ADM');
    this.dataAdm = '';

    localStorage.removeItem('87345yiu34h5g348734yu348734u3587uy34873yu34STD');
    this.dataStd = '';

    localStorage.removeItem('34j3jh34jhg34jh3jh34jh3jh34jhj4sdfsfsdfs4234TB');
    this.dataTb = '';

    localStorage.removeItem('89234yug387g23e283dg823dg2837t238d72t37623PRV');
    this.dataPrv = '';

    localStorage.removeItem('89234yug387g23e283dg823dg2837t238d72t37623AJM');
    this.dataAjm = '';

    localStorage.removeItem('89234yug387g23e283dg823dg2837t238d72t37623AJW');
    this.dataAjw = '';

    localStorage.removeItem('89234yug387g23e283dg823dg2837t238d72t37623TNR');
    this.dataTnr = '';


    this.getTenor();
    this.getJenisSer();
    this.getKepSer();
    this.getPayTip();
    this.getCosMan();

    // this.inputBiayaCHeck();
    this.inputPjCheck();


    // this.idData = this.navParams.data.id;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.no) {
        this.idData = params.no;
        console.log('idData', this.idData)
      }
    });
    console.log(this.idData);
    if (this.idData) {
      this.disabledInput = true;
      this.detailData()
    } else {
      this.disabledInput = false;
      this.tampilData = true;
    }
  }

  checkPercentage(event: any, model: any) {
    let value = event.target.value;

    // Mengganti koma dengan titik jika ada
    value = value.replace(',', '.');

    // Menghapus karakter selain angka dan titik
    value = value.replace(/[^0-9.]/g, '');

    // Mengizinkan hanya satu titik desimal
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts[1];
    }

    // Mengubah nilai menjadi angka
    let numericValue = parseFloat(value);

    // Jika nilai lebih dari 100, ubah menjadi 100
    if (numericValue > 100) {
      numericValue = 100;
      value = '100';
    }

    // Membatasi nilai desimal menjadi dua angka
    if (parts.length === 2) {
      parts[1] = parts[1].substring(0, 2);
      value = parts.join('.');
    }

    // Memperbarui nilai di model
    this[model] = value;

    // Memperbarui nilai di input
    event.target.value = value;
  }


  anti_special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  checkZero(field: string) {
    if (this[field] && this[field].length > 1 && this[field][0] === '0') {
      if (this[field] == '' || this[field].length <= 0) {
        this[field] = '0';
      } else {
        this[field] = this[field].substring(1);
      }
    }
  }

  resetValue(modelName: string) {
    let sp = this[modelName + 'Sepa'];
    this[modelName] = '';
    this[modelName + 'Sepa'] = '';
  }

  separator(modelName: string) {
    let val = this[modelName + 'Sepa'];
    console.log('val', val)
    let vale = val.replace(/[^0-9\.]/g, '');
    this[modelName + 'Sepa'] = vale;
    if (vale !== "") {
      let valArr = vale.split('.');
      valArr[0] = (parseInt(valArr[0], 10)).toLocaleString();
      let valex = valArr.join('.');
      this[modelName + 'Sepa'] = valex;
      let b = valex.replace(/,/g, '').replace(/\./g, '');
      this[modelName] = Number.parseInt(b, 10);
    } else {
      this[modelName + 'Sepa'] = '';
      this[modelName] = 0;
    }
    console.log(`${modelName}Sepa`, this[modelName + 'Sepa']);
    console.log(modelName, this[modelName]);
  }

  // iskeyUpNumberinitStockSepa() {
  //   var val = this.initStockSepa;
  //   let vale = val.replace(/[^0-9\.]/g, '');
  //   this.initStockSepa = vale;
  //   console.log('vale', vale)
  //   if (vale != "") {
  //     let valArr = vale.split('.');
  //     valArr[0] = (parseInt(valArr[0], 10)).toLocaleString();
  //     var valex = valArr.join('.');
  //     this.initStockSepa = valex;
  //     var b = valex.replaceAll(',', '').replaceAll('.', '');
  //     this.initStock = Number.parseInt(b);
  //   } else {
  //     this.initStockSepa = '';
  //     this.initStock = '';
  //   }
  //   console.log('initStockSepa', this.initStockSepa)
  //   console.log('initStock', this.initStock)
  // }



  validateRangePlafonA() {
    if (this.submission_loan_min > this.submission_loan_max) {
      this.warnText = true;
    } else {
      this.warnText = false;
    }
  }

  checkEvent() {
    if (this.limitLtv == true) {
      this.disabledInputLTV = false;
    } else {
      this.disabledInputLTV = true;
    }
  }

  getCosMan() {
    this.load = true;
    this.api.get('data/master/cost-managament')
      .subscribe((data) => {
        this.cosMan = data;
        if (this.cosMan.error == 0) {
          this.load = false;
          // this.cm = this.cosMan.data;
          var bb = this.cosMan.data;
          let newData2 = bb.filter(obj2 => !this.tagsx.includes(obj2.id));
          this.cm = newData2;
          this.tagsx = ['ADM'];
          this.inputBiayaCHeck();
          console.log('tagsx', this.tagsx)
        } else {
          this.load = false;
          this.util.showToast(this.cosMan.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  getPayTip() {
    this.load = true;
    this.api.get('data/master/payment-type')
      .subscribe((data) => {
        this.payTip = data;
        if (this.payTip.error == 0) {
          this.load = false;
          this.pt = this.payTip.data;
        } else {
          this.load = false;
          this.util.showToast(this.payTip.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  getKepSer() {
    this.load = true;
    this.api.get('data/master/kepemilikan-sertifikat')
      .subscribe((data) => {
        this.kepSer = data;
        if (this.kepSer.error == 0) {
          this.load = false;
          var dat = this.kepSer.data;
          dat.forEach(obj => {
            obj.isTrue = false;
          });
          this.ks = dat;

        } else {
          this.load = false;
          this.util.showToast(this.kepSer.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  getJenisSer() {
    this.load = true;
    this.api.get('data/master/jenis-sertifikat')
      .subscribe((data) => {
        this.jenSer = data;
        if (this.jenSer.error == 0) {
          this.load = false;
          this.js = this.jenSer.data;
        } else {
          this.load = false;
          this.util.showToast(this.jenSer.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  getTenor() {
    this.load = true;
    this.api.get('data/master/tenor')
      .subscribe((data) => {
        this.ten = data;
        if (this.ten.error == 0) {
          this.load = false;
          // this.tn = this.ten.data;
          var bb = this.ten.data;
          let newData2 = bb.filter(obj2 => !this.tags.includes(obj2.id));
          this.tn = this.ten.data
          this.tnx = newData2;
          console.log('tn', this.tn)
          this.inputTenorCheck();

        } else {
          this.load = false;
          this.util.showToast(this.ten.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }


  detailData() {
    this.api.get('backoffice/master/product/list/' + this.idData)
      .subscribe((data) => {
        this.detData = data;
        if (this.detData.error == 0) {
          this.nama = this.detData.data.product_name;
          this.desc = this.detData.data.product_description;
          this.ketentuan = this.detData.data.term;

          if (this.detData.data.certificates.length !== 0) {
            var bb = this.detData.data.certificates
            var transformedData = bb.map(function (item) {
              return {
                "type_id": item.type.id,
                "owner_id": item.owners.map(function (owner) {
                  return owner.id;
                })
              };
            });
            var aa = JSON.stringify(transformedData);
            localStorage.setItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj', aa);
            this.dataChar = JSON.parse(localStorage.getItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj') || '');
          }

          this.interest_rate_type = this.detData.data.interest_rate_type;
          if (this.detData.data.submission_tenors.length !== 0) {
            var cc = JSON.stringify(this.detData.data.submission_tenors);
            localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623TNR', cc);
            this.dataTnr = JSON.parse(localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623TNR') || '');
          }
          this.tagsx = [];
          if (this.detData.data.cost_managaments !== null) {
            // var qq = this.detData.data.cost_managaments.find((item: any) => item.id === 'ADM');
            this.prv = this.detData.data.cost_managaments.find((item: any) => item.id === 'PRV');
            this.adm = this.detData.data.cost_managaments.find((item: any) => item.id === 'ADM');
            this.crc = this.detData.data.cost_managaments.find((item: any) => item.id === 'CRC');
            this.ajm = this.detData.data.cost_managaments.find((item: any) => item.id === 'AJM');
            this.ajw = this.detData.data.cost_managaments.find((item: any) => item.id === 'AJW');
            if (this.adm) {
              if ('ADM'.trim() !== '') {
                this.tagsx.push('ADM');
              }
              if (this.adm.type == 'FREE') {
                this.admtype = 'free';
              } else if (this.adm.type == 'FIXED') {
                this.admtype = 'fixed';
                this.admtypepercent = this.adm.jenis;
                this.admtypefix = this.adm.value;
              } else if (this.adm.type == 'RANGE') {
                this.admtype = 'range';
                this.admjenis = this.adm.jenis;
                if(this.admjenis == 'Standard'){
                  const arrayWithCond = this.adm.type_dtl.filter(item => 'cond' in item);
                  const arrayWithoutCond = this.adm.type_dtl.filter(item => !('cond' in item));

                  localStorage.setItem('34j3jh34jhg34jh3jh34jh3jh34jhj4sdfsfsdfs4234TB', JSON.stringify(arrayWithCond));
                  localStorage.setItem('87345yiu34h5g348734yu348734u3587uy34873yu34STD', JSON.stringify(arrayWithoutCond));

                  this.dataTb = JSON.parse(localStorage.getItem('34j3jh34jhg34jh3jh34jh3jh34jhj4sdfsfsdfs4234TB') || '');
                  this.dataStd = JSON.parse(localStorage.getItem('87345yiu34h5g348734yu348734u3587uy34873yu34STD') || '');
                }else{
                  this.dataAdm = this.adm.type_dtl;
                }
              }
            }

            if (this.prv) {
              if ('PRV'.trim() !== '') {
                this.tagsx.push('PRV');
              }
              if (this.prv.type == 'FREE') {
                this.prvtype = 'free';
              } else if (this.prv.type == 'FIXED') {
                this.prvtype = 'fixed';
                this.prvtypepercent = this.prv.jenis;
                this.prvtypefix = this.prv.value;
              } else if (this.prv.type == 'RANGE') {
                this.prvtype = 'range';
                this.dataPrv = this.prv.type_dtl;
              }
            }

            if (this.crc) {
              if ('CRC'.trim() !== '') {
                this.tagsx.push('CRC');
              }
              if (this.crc.type == 'FREE') {
                this.crctype = 'free';
              } else if (this.crc.type == 'FIXED') {
                this.crctype = 'fixed';
                this.crctypepercent = this.crc.jenis;
                this.crctypefix = this.crc.value;
              } else if (this.crc.type == 'RANGE') {
                this.crctype = 'range';
                this.dataCrc = this.crc.type_dtl;
              }
            }

            if (this.ajm) {
              if ('AJM'.trim() !== '') {
                this.tagsx.push('AJM');
              }
              if (this.ajm.type == 'FREE') {
                this.ajmtype = 'free';
              } else if (this.ajm.type == 'FIXED') {
                this.ajmtype = 'fixed';
                this.ajmtypepercent = this.ajm.jenis;
                this.ajmtypefix = this.ajm.value;
              } else if (this.ajm.type == 'RANGE') {
                this.ajmtype = 'range';
                this.dataAjm = this.ajm.type_dtl;
              }
            }

            if (this.ajw) {
              if ('AJW'.trim() !== '') {
                this.tagsx.push('AJW');
              }
              if (this.ajw.type == 'FREE') {
                this.ajwtype = 'free';
              } else if (this.ajw.type == 'FIXED') {
                this.ajwtype = 'fixed';
                this.ajwtypepercent = this.ajw.jenis;
                this.ajwtypefix = this.ajw.value;
              } else if (this.ajw.type == 'RANGE') {
                this.ajwtype = 'range';
                this.dataAjw = this.ajw.type_dtl;
              }
            }
          }


          this.tags = this.detData.data.submission_tenors.id;

          this.interest_rate = this.detData.data.submission_tenors.interest_rate;
          this.payment_type_id = this.detData.data.payment_type_id;
          this.submission_loan_min = parseInt(this.detData.data.submission_loan_min, 10);
          this.submission_loan_max = parseInt(this.detData.data.submission_loan_max, 10);
          if (this.submission_loan_min > this.submission_loan_max) {
            this.warnText = true;
          } else {
            this.warnText = false;
          }
          console.log('submission_loan_min', this.submission_loan_min)
          console.log('submission_loan_max', this.submission_loan_max)
          console.log('warnText', this.warnText)

          // this.validateRangePlafonA();
          this.maxltv = this.detData.data.ltv_value;
          if (this.detData.data.use_ltv == 'Y') {
            this.limitLtv = true;
          } else {
            this.limitLtv = false;
          }
          setTimeout(() => {
            // this.inputBiayaCHeck();
            this.inputTenorCheck();
            this.inputPjCheck();
            this.inputBiayaCHeck();
            this.tampilData = true;
            this.load = false;
          }, 1500);

        } else {
          this.load = false;
          this.util.showToast(this.detData.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  mergeLocalStorageItems() {
    const storageTB = localStorage.getItem('34j3jh34jhg34jh3jh34jh3jh34jhj4sdfsfsdfs4234TB');
    const storageSTD = localStorage.getItem('87345yiu34h5g348734yu348734u3587uy34873yu34STD');

    const arrayTB = storageTB ? JSON.parse(storageTB) : [];
    const arraySTD = storageSTD ? JSON.parse(storageSTD) : [];

    if (arrayTB.length > 0 && arraySTD.length > 0) {
        const mergedArray = [...arrayTB, ...arraySTD];
        return mergedArray;
    } else if (arrayTB.length > 0 && arraySTD.length == 0) {
      const mergedArray = arrayTB;
      return mergedArray;
    } else if (arrayTB.length == 0 && arraySTD.length > 0) {
      const mergedArray = arraySTD;
      return mergedArray;
    } else {
        return [];
    }
}

  save() {
    this.load = true;
    if (this.limitLtv == true) {
      var limitLtv = 'Y';
    } else {
      var limitLtv = 'N';
    }

    // ==========  ADMIN  ============
    if (this.admtype == 'free') {
      this.admx = {
        id: 'ADM',
        type: 'FREE'
      }
    } else if (this.admtype == 'fixed') {
      this.admx = {
        id: 'ADM',
        type: 'FIXED',
        jenis: this.admtypepercent,
        value: this.admtypefix
      }
    } else if (this.admtype == 'range') {
      const mergedResult = this.mergeLocalStorageItems();
      console.log('mergedResult',mergedResult)
      if(this.admjenis == 'Standard'){
        this.admx = {
          id: 'ADM',
          type: 'RANGE',
          jenis: 'Standard',
          type_dtl: mergedResult
        }
      }else{
        this.admx = {
          id: 'ADM',
          type: 'RANGE',
          jenis: 'Tiered',
          type_dtl: this.dataAdm
        }
      }
    }
    console.log('admx', this.admx)

    // ==========  PRV  ============
    if (this.prvtype == 'free') {
      this.prvx = {
        id: 'PRV',
        type: 'FREE'
      }
    } else if (this.prvtype == 'fixed') {
      this.prvx = {
        id: 'PRV',
        type: 'FIXED',
        jenis: this.prvtypepercent,
        value: this.prvtypefix
      }
    } else if (this.prvtype == 'range') {
      this.prvx = {
        id: 'PRV',
        type: 'RANGE',
        type_dtl: this.dataPrv
      }
    }
    console.log('prvx', this.prvx)

    // ==========  CRC  ============
    if (this.crctype == 'free') {
      this.crcx = {
        id: 'CRC',
        type: 'FREE'
      }
    } else if (this.crctype == 'fixed') {
      this.crcx = {
        id: 'CRC',
        type: 'FIXED',
        jenis: this.crctypepercent,
        value: this.crctypefix
      }
    } else if (this.crctype == 'range') {
      this.crcx = {
        id: 'CRC',
        type: 'RANGE',
        type_dtl: this.dataCrc
      }
    }
    console.log('crcx', this.crcx)

    // ==========  ajmx  ============
    if (this.ajmtype == 'free') {
      this.ajmx = {
        id: 'AJM',
        type: 'FREE'
      }
    } else if (this.ajmtype == 'fixed') {
      this.ajmx = {
        id: 'AJM',
        type: 'FIXED',
        jenis: this.ajmtypepercent,
        value: this.ajmtypefix
      }
    } else if (this.ajmtype == 'range') {
      this.ajmx = {
        id: 'AJM',
        type: 'RANGE',
        type_dtl: this.dataAjm
      }
    }
    console.log('ajmx', this.ajmx)

    // ==========  ajwx  ============
    if (this.ajwtype == 'free') {
      this.ajwx = {
        id: 'AJW',
        type: 'FREE'
      }
    } else if (this.ajwtype == 'fixed') {
      this.ajwx = {
        id: 'AJW',
        type: 'FIXED',
        jenis: this.ajwtypepercent,
        value: this.ajwtypefix
      }
    } else if (this.ajwtype == 'range') {
      this.ajwx = {
        id: 'AJW',
        type: 'RANGE',
        type_dtl: this.dataAjw
      }
    }
    console.log('ajwx', this.ajwx)

    // this.mergedArray = [this.admx, this.prvx, this.crcx, this.ajmx, this.ajwx];
    this.mergedArray = [];
    if (this.tagsx.includes('ADM')) {
      this.mergedArray.push(this.admx);
    }

    if (this.tagsx.includes('PRV')) {
      this.mergedArray.push(this.prvx);
    }

    if (this.tagsx.includes('CRC')) {
      this.mergedArray.push(this.crcx);
    }

    if (this.tagsx.includes('AJM')) {
      this.mergedArray.push(this.ajmx);
    }

    if (this.tagsx.includes('AJW')) {
      this.mergedArray.push(this.ajwx);
    }
    console.log('mergedArray', this.mergedArray);

    if (this.interest_rate_type == 'fixed') {
      var submission_tenors = {
        id: this.tags,
        interest_rate: this.interest_rate
      };
      this.form = {
        product_name: this.nama,
        product_description: this.desc,
        term: this.ketentuan,
        certificates: this.dataChar,
        submission_loan_min: this.submission_loan_min,
        submission_loan_max: this.submission_loan_max,
        interest_rate_type: this.interest_rate_type,
        submission_tenors: submission_tenors,
        payment_type_id: this.payment_type_id,
        cost_managaments: this.mergedArray,
        use_ltv: limitLtv,
        ltv_value: this.maxltv,
      };
    } else {
      this.form = {
        product_name: this.nama,
        product_description: this.desc,
        term: this.ketentuan,
        certificates: this.dataChar,
        submission_loan_min: this.submission_loan_min,
        submission_loan_max: this.submission_loan_max,
        interest_rate_type: this.interest_rate_type,
        submission_tenors: this.dataTnr,
        payment_type_id: this.payment_type_id,
        cost_managaments: this.mergedArray,
        use_ltv: limitLtv,
        ltv_value: this.maxltv,
      };
    }


    console.log('form', this.form)
    if (this.idData) {
      this.api.put('backoffice/master/product/' + this.idData, this.form)
        .subscribe((data) => {
          this.saving = data;
          if (this.saving.error == 0) {
            this.load = false;
            this.util.showToast(this.saving.message, 'success', 'bottom');
            this.router.navigate(['./produk']);
          } else {
            this.load = false;
            this.util.showToast(this.saving.message, 'danger', 'bottom');
          }
        }, (error) => {
          this.load = false;
          console.log('error', error);
          this.api.errorCond(error)
        });
    } else {
      this.api.post('backoffice/master/product', this.form)
        .subscribe((data) => {
          this.saving = data;
          if (this.saving.error == 0) {
            this.load = false;
            this.util.showToast(this.saving.message, 'success', 'bottom');
            // this.api.dismissData('','confirm');
            this.router.navigate(['./produk']);
          } else {
            this.load = false;
            this.util.showToast(this.saving.message, 'danger', 'bottom');
          }
        }, (error) => {
          this.load = false;
          console.log('error', error);
          this.api.errorCond(error)
        });
    }
  }

  checkEventPj() {
    this.ks.map(obj => obj.id);
    var aa = this.ks.filter(item => item.isTrue == true);
    this.peJam = aa.map(obj => obj.id);
    // console.log('resAr',this.ks);
    console.log('peJam', this.peJam);
  }

  removeTagPj(tagToRemove: string) {
    this.peJam = this.peJam.filter(peJam => peJam !== tagToRemove);
    this.inputPjCheck()
  }

  addTagPj(p: any) {
    if (p.trim() !== '') {
      this.peJam.push(p);
    }
    console.log(this.peJam)
    this.inputPjCheck()
  }

  inputPjCheck() {
    this.gg = this.peJam.find((item: any) => item === 'MS');
    this.kk = this.peJam.find((item: any) => item === 'OL');
    this.hh = this.peJam.find((item: any) => item === 'OT');
    this.ii = this.peJam.find((item: any) => item === 'PS');
    this.jj = this.peJam.find((item: any) => item === 'AN');
  }

  removeTag(tagToRemove: string) {
    this.tags = this.tags.filter(tag => tag !== tagToRemove);
    this.inputTenorCheck()
  }

  addTag(p: any) {
    if (p.trim() !== '') {
      this.tags.push(p);
    }
    console.log('tags',this.tags)
    this.inputTenorCheck()
  }

  inputTenorCheck() {
    var bb = this.ten.data;
    let newData2 = bb.filter(obj2 => !this.tags.includes(obj2.id));
    this.tnx = newData2;
    this.tn = bb;
    // this.a = this.tags.find((item: any) => item === '12M');
    // this.b = this.tags.find((item: any) => item === '18M');
    // this.c = this.tags.find((item: any) => item === '24M');
    // this.d = this.tags.find((item: any) => item === '36M');
    // this.e = this.tags.find((item: any) => item === '42M');
    // this.f = this.tags.find((item: any) => item === '48M');
  }

  removeTagx(tagToRemove: string) {
    this.tagsx = this.tagsx.filter(tag => tag !== tagToRemove);
    this.inputBiayaCHeck()
  }

  addTagx(p: any) {
    if (p.trim() !== '') {
      this.tagsx.push(p);
    }
    console.log('tagsx', this.tagsx)
    this.inputBiayaCHeck()
  }

  inputBiayaCHeck() {
    var bb = this.cosMan.data;
    let newData2 = bb.filter(obj2 => !this.tagsx.includes(obj2.id));
    this.cm = newData2;
    console.log('cm', this.cm)
    this.prv = this.tagsx.find((item: any) => item === 'PRV');
    this.adm = this.tagsx.find((item: any) => item === 'ADM');
    this.crc = this.tagsx.find((item: any) => item === 'CRC');
    this.ajm = this.tagsx.find((item: any) => item === 'AJM');
    this.ajw = this.tagsx.find((item: any) => item === 'AJW');
    console.log('adm', this.adm)
  }

  validasiTenor() {
    try {
      let intValue = parseInt(this.minTenor.replace(/\D/g, ''), 10);
      var minTenor = isNaN(intValue) ? 0 : intValue;
      console.log(minTenor);
    } catch (error) {
      console.log(error);
    }

    try {
      let intValuea = parseInt(this.maxTenor.replace(/\D/g, ''), 10);
      var maxTenor = isNaN(intValuea) ? 0 : intValuea;
      console.log(maxTenor);
    } catch (error) {
      console.log(error);
    }

    if (minTenor > maxTenor) {
      this.warnTextTenor = true;
    } else {
      this.warnTextTenor = false;
    }
  }

  tambahTnr() {
    if (this.minTenor == '' || this.maxTenor == '' || this.interest_rate == '') {
      this.util.showToast('Mohon Melengkapi Form Sebelum menambahkan Form Baru', 'danger', 'bottom');
      this.interest_rate = !this.interest_rate;
    } else if (this.warnTextTenor == true) {
      this.util.showToast('Mohon Perbaiki Validasi Tersebut', 'danger', 'bottom');
      this.interest_rate = !this.interest_rate;
    } else {
      this.form = JSON.stringify({
        min_id: this.minTenor,
        max_id: this.maxTenor,
        interest_rate: this.interest_rate
      });

      if (this.dataTnr.length == 0) {
        var t = '[' + this.form + ']';
        localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623TNR', t);
      } else {
        const lastData = this.dataTnr[this.dataTnr.length - 1];
        console.log('lastData', lastData)
        let intValue = parseInt(lastData.max_id.replace(/\D/g, ''), 10);
        var minTenor = isNaN(intValue) ? 0 : intValue;
        console.log('minTenor', minTenor);
        const lastMaxPlafon = minTenor
        if (parseInt(this.minTenor) < lastMaxPlafon) {
          this.util.showToast('Mohon Perbaiki Validasi Input Range Maksimal & Minimal Tersebut', 'danger', 'bottom');
          this.interest_rate = !this.interest_rate;
        } else {
          this.listBillx = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623TNR')
          let aa = JSON.parse(this.listBillx);
          console.log('aa', aa)
          let gg = JSON.parse(this.form);
          console.log('notsame');
          var asa = this.listBillx.replace("]", "");
          let dataWithComa = ',' + this.form + ']';
          console.log('dataWithComa', dataWithComa);
          var j = asa + dataWithComa;
          localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623TNR', j);
        }
      }
      this.minTenor = '';
      this.maxTenor = '';
      this.interest_rate = !this.interest_rate;
      this.dataTnr = JSON.parse(localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623TNR') || '');
    }
  }

  async deleteTnr(i: any) {
    console.log('hapus')
    var indextodelete = i;
    var a = this.dataTnr
    a.splice(indextodelete, 1);
    // PUT TO LS
    let x = JSON.stringify(a);
    console.log(x)
    localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623TNR', x);
    // GET
    let ax = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623TNR') || '';
    this.dataTnr = JSON.parse(ax);
    console.log('dataTnr Hapus', this.dataTnr)
  }



  tambahAjw() {
    console.log(this.ajwtypeMin + ' -- ' + this.ajwtypeMax + ' -- ' + this.ajwtypeValue)
    if (this.ajwtypeMin == '' || this.ajwtypeMax == '' || this.ajwtypeValue == '') {
      this.util.showToast('Mohon Melengkapi Form Sebelum menambahkan Form Baru', 'danger', 'bottom');
      // this.ajwtypeValue = !this.ajwtypeValue;
      this.ajwtypeValue = '';
    }
    // else if (this.warnTextajw == true || this.warnTextajw2 == true) {
    //   this.util.showToast('Mohon Perbaiki Validasi Input Range Maksimal & Minimal Tersebut', 'danger', 'bottom');
    // } 
    else if (this.ajwtypeMin > this.ajwtypeMax) {
      this.warnTextajw2 = true;
      this.util.showToast('Minimal Harus Lebih Rendah dari Maksimal', 'danger', 'bottom');
      // this.ajwtypeValue = !this.ajwtypeValue;
      this.ajwtypeValue = '';
    } else {

      this.form = JSON.stringify({
        min_plafon: this.ajwtypeMin,
        max_plafon: this.ajwtypeMax,
        jenis: this.ajwtypepercent,
        value: this.ajwtypeValue
      });

      if (this.dataAjw.length == 0) {
        var t = '[' + this.form + ']';
        localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623AJW', t);
      } else {
        const lastData = this.dataAjw[this.dataAjw.length - 1];
        const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
        if (parseInt(this.ajwtypeMin) < lastMaxPlafon) {
          this.util.showToast('Mohon Perbaiki Validasi Input Range Maksimal & Minimal Tersebut', 'danger', 'bottom');
          // this.ajwtypeValue = !this.ajwtypeValue;
          this.ajwtypeValue = '';
        } else {
          this.listBillx = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623AJW')
          let aa = JSON.parse(this.listBillx);
          console.log('aa', aa)
          let gg = JSON.parse(this.form);
          console.log('notsame');
          var asa = this.listBillx.replace("]", "");
          let dataWithComa = ',' + this.form + ']';
          console.log('dataWithComa', dataWithComa);
          var j = asa + dataWithComa;
          localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623AJW', j);
        }
      }
      this.ajwtypeMin = '';
      this.ajwtypeMax = '';
      this.ajwtypeValue = '';
      this.dataAjw = JSON.parse(localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623AJW') || '');

      const lastData = this.dataAjw[this.dataAjw.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      this.ajwtypeMin = newMaxPlafon
      console.log(newMaxPlafon);

    }
  }

  async deleteAjw(i: any) {
    console.log('hapus')
    var indextodelete = i;
    var a = this.dataAjw
    a.splice(indextodelete, 1);
    // PUT TO LS
    let x = JSON.stringify(a);
    console.log(x)
    localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623AJM', x);
    // GET
    let ax = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623AJM') || '';
    this.dataAjw = JSON.parse(ax);
    console.log('dataAjw Hapus', this.dataAjw)
  }

  validateRangePlafonAjw() {
    if (this.ajwtypeMin >= this.ajwtypeMax) {
      this.warnTextajw = true;
    } else {
      this.warnTextajw = false;
    }
  }

  tambahAjm() {
    console.log(this.ajmtypeMin + ' -- ' + this.ajmtypeMax + ' -- ' + this.ajmtypeValue)
    if (this.ajmtypeMin == '' || this.ajmtypeMax == '' || this.ajmtypeValue == '') {
      this.util.showToast('Mohon Melengkapi Form Sebelum menambahkan Form Baru', 'danger', 'bottom');
      // this.ajmtypeValue = !this.ajmtypeValue;
      this.ajmtypeValue = '';
    }
    // else if (this.warnTextajm == true || this.warnTextajm2 == true) {
    //   this.util.showToast('Mohon Perbaiki Validasi Input Range Maksimal & Minimal Tersebut', 'danger', 'bottom');
    // } 
    else if (this.ajmtypeMin > this.ajmtypeMax) {
      this.warnTextajm2 = true;
      this.util.showToast('Minimal Harus Lebih Rendah dari Maksimal', 'danger', 'bottom');
      // this.ajmtypeValue = !this.ajmtypeValue;
      this.ajmtypeValue = '';
    } else {
      this.form = JSON.stringify({
        min_plafon: this.ajmtypeMin,
        max_plafon: this.ajmtypeMax,
        jenis: this.ajmtypepercent,
        value: this.ajmtypeValue
      });

      if (this.dataAjm.length == 0) {
        var t = '[' + this.form + ']';
        localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623AJM', t);
      } else {
        const lastData = this.dataAjm[this.dataAjm.length - 1];
        const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
        if (parseInt(this.ajmtypeMin) < lastMaxPlafon) {
          this.util.showToast('Mohon Perbaiki Validasi Input Range Maksimal & Minimal Tersebut', 'danger', 'bottom');
          // this.ajmtypeValue = !this.ajmtypeValue;
          this.ajmtypeValue = '';
        } else {
          this.listBillx = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623AJM')
          let aa = JSON.parse(this.listBillx);
          console.log('aa', aa)
          let gg = JSON.parse(this.form);
          console.log('notsame');
          var asa = this.listBillx.replace("]", "");
          let dataWithComa = ',' + this.form + ']';
          console.log('dataWithComa', dataWithComa);
          var j = asa + dataWithComa;
          localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623AJM', j);
        }
      }
      this.ajmtypeMin = '';
      this.ajmtypeMax = '';
      this.ajmtypeValue = '';
      this.dataAjm = JSON.parse(localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623AJM') || '');

      const lastData = this.dataAjm[this.dataAjm.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      this.ajmtypeMin = newMaxPlafon
      console.log(newMaxPlafon);
    }
  }

  async deleteAjm(i: any) {
    console.log('hapus')
    var indextodelete = i;
    var a = this.dataAjm
    a.splice(indextodelete, 1);
    // PUT TO LS
    let x = JSON.stringify(a);
    console.log(x)
    localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623AJM', x);
    // GET
    let ax = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623AJM') || '';
    this.dataAjm = JSON.parse(ax);
    console.log('dataAjm Hapus', this.dataAjm)
  }

  validateRangePlafonAjm() {
    if (this.ajmtypeMin >= this.ajmtypeMax) {
      this.warnTextajm = true;
    } else {
      this.warnTextajm = false;
    }
  }

  tambahCrc() {
    if (this.crcTenor == '' || this.crctypeMin == 0 || this.crctypeMax == '' || this.crctypeValue == '') {
      this.util.showToast('Mohon Melengkapi Form Sebelum menambahkan Form Baru', 'danger', 'bottom');
      // this.crctypeValue = !this.crctypeValue;
      this.crctypeValue = '';
      this.crctypeValueSepa = '';
    }
    // else if (this.warnTextajm == true || this.warnTextajm2 == true) {
    //   this.util.showToast('Mohon Perbaiki Validasi Input Range Maksimal & Minimal Tersebut', 'danger', 'bottom');
    // } 
    else if (this.crctypeMin > this.crctypeMax) {
      this.warnTextcrc = true;
      // this.crctypeValue = !this.crctypeValue;
      this.crctypeValue = '';
      this.crctypeValueSepa = '';
      this.util.showToast('Minimal Harus Lebih Rendah dari Maksimal', 'danger', 'bottom');
    } else {

      try {
        this.crctypeValue = this.crctypeValue.replace(',', '.');
      } catch (error) {
        console.log('nocomma')
      }

      this.form = JSON.stringify({
        min_plafon: this.crctypeMin,
        max_plafon: this.crctypeMax,
        jenis: this.crctypepercent,
        value: this.crctypeValue,
        tenor_id: this.crcTenor,
        add_type: this.crctypetambahan,
        add_value: this.crctambahan
      });

      if (this.dataCrc.length == 0) {
        var t = '[' + this.form + ']';
        localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623CRC', t);
      } else {
        const lastData = this.dataCrc[this.dataCrc.length - 1];
        const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
        if (parseInt(this.crctypeMin) < lastMaxPlafon) {
          this.util.showToast('Mohon Perbaiki Validasi Input Range Maksimal & Minimal Tersebut', 'danger', 'bottom');
          // this.crctypeValue = !this.crctypeValue;
          this.crctypeValue = '';
          this.crctypeValueSepa = '';
        } else {
          this.listBillx = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623CRC')
          let aa = JSON.parse(this.listBillx);
          console.log('aa', aa)
          let gg = JSON.parse(this.form);
          console.log('notsame');
          var asa = this.listBillx.replace("]", "");
          let dataWithComa = ',' + this.form + ']';
          console.log('dataWithComa', dataWithComa);
          var j = asa + dataWithComa;
          localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623CRC', j);
        }
      }
      this.crctypeMin = '';
      this.crctypeMax = '';
      this.crctypeValue = '';
      this.crcTenor = '';
      this.crctambahan = '';

      this.crctypeMinSepa = '';
      this.crctypeMaxSepa = '';
      this.crctypeValueSepa = '';
      this.crctambahanSepa = '';

      this.dataCrc = JSON.parse(localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623CRC') || '');

      const lastData = this.dataCrc[this.dataCrc.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      this.crctypeMin = newMaxPlafon;
      this.crctypeMinSepa = newMaxPlafon;

      console.log(newMaxPlafon);
    }
  }

  async deleteCrc(i: any) {
    console.log('hapus')
    var indextodelete = i;
    var a = this.dataCrc
    a.splice(indextodelete, 1);
    // PUT TO LS
    let x = JSON.stringify(a);
    console.log(x)
    localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623CRC', x);
    // GET
    let ax = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623CRC') || '';
    this.dataCrc = JSON.parse(ax);
    console.log('dataCrc Hapus', this.dataCrc);

    this.crctypeMin = '';
    this.crctypeMax = '';
    this.crctypeValue = '';
    this.crcTenor = '';
    this.crctambahan = '';

    this.crctypeMinSepa = '';
    this.crctypeMaxSepa = '';
    this.crctypeValueSepa = '';
    this.crctambahanSepa = '';
  }

  validateRangePlafonCrc(model: any) {
    this.separator(model);
    if (this.dataCrc.length == 0) {
      if (this.crctypeMin >= this.crctypeMax) {
        this.warnTextcrc = true;
      } else {
        this.warnTextcrc = false;
      }
    } else {
      const lastData = this.dataCrc[this.dataCrc.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;

      if (parseInt(this.crctypeMin) < newMaxPlafon) {
        this.crctypeMin = String(newMaxPlafon);
        console.log('da');
      } else {
        console.log('xx')
      }
    }
  }

  tambahPrv() {
    console.log(this.prvtypeMin + ' -- ' + this.prvtypeMax + ' -- ' + this.prvtypeValue)
    if (this.prvTenor == '' || this.prvtypeMin == '' || this.prvtypeMax == '' || this.prvtypeValue == '' || this.prvtypeValue == 0) {
      this.util.showToast('Mohon Melengkapi Form Sebelum menambahkan Form Baru', 'danger', 'bottom');
      // this.prvtypeMax = '';
      this.prvtypeValue = '';
      this.prvtypeValueSepa = '';
    }
    // else if (this.warnTextprv == true || this.warnTextprv2 == true) {
    //   this.util.showToast('Mohon Perbaiki Validasi Input Range Maksimal & Minimal Tersebut', 'danger', 'bottom');
    // } 
    else if (this.prvtypeMin > this.prvtypeMax) {
      this.warnTextprv = true;
      this.util.showToast('Minimal Harus Lebih Rendah dari Maksimal', 'danger', 'bottom');
      this.prvtypeMax = '';
      this.prvtypeValue = '';
      this.prvtypeMaxSepa = '';
      this.prvtypeValueSepa = '';
    } else {

      try {
        this.prvtypeValue = this.prvtypeValue.replace(',', '.');
      } catch (error) {
        console.log('nocomma')
      }

      this.form = JSON.stringify({
        min_plafon: this.prvtypeMin,
        max_plafon: this.prvtypeMax,
        jenis: this.prvtypepercent,
        value: this.prvtypeValue,
        tenor_id: this.prvTenor,
        add_type: this.prvtypetambahan,
        add_value: this.prvtambahan
      });

      if (this.dataPrv.length == 0) {
        var t = '[' + this.form + ']';
        localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623PRV', t);
      } else {
        const lastData = this.dataPrv[this.dataPrv.length - 1];
        const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
        if (parseInt(this.prvtypeMin) < lastMaxPlafon) {
          // this.prvtypeValue = !this.prvtypeValue;
          this.prvtypeValue = '';
          this.prvtypeValueSepa = '';
          this.util.showToast('Mohon Perbaiki Validasi Input Range Maksimal & Minimal Tersebut', 'danger', 'bottom');
        } else {
          this.listBillx = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623PRV')
          let aa = JSON.parse(this.listBillx);
          console.log('aa', aa)
          let gg = JSON.parse(this.form);
          console.log('notsame');
          var asa = this.listBillx.replace("]", "");
          let dataWithComa = ',' + this.form + ']';
          console.log('dataWithComa', dataWithComa);
          var j = asa + dataWithComa;
          localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623PRV', j);
        }
      }
      this.prvtypeMin = '';
      this.prvtypeMax = '';
      this.prvtypeValue = '';
      this.prvTenor = '';
      this.prvtambahan = '';

      this.prvtypeMinSepa = '';
      this.prvtypeMaxSepa = '';
      this.prvtypeValueSepa = '';
      this.prvtambahanSepa = '';

      this.dataPrv = JSON.parse(localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623PRV') || '');

      const lastData = this.dataPrv[this.dataPrv.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      this.prvtypeMin = newMaxPlafon
      console.log(newMaxPlafon);
    }
  }

  async deletePrv(i: any) {
    console.log('hapus')
    var indextodelete = i;
    var a = this.dataPrv
    a.splice(indextodelete, 1);
    // PUT TO LS
    let x = JSON.stringify(a);
    console.log(x)
    localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623PRV', x);
    // GET
    let ax = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623PRV') || '';
    this.dataPrv = JSON.parse(ax);
    console.log('dataPrv Hapus', this.dataPrv);

    this.prvtypeMin = '';
    this.prvtypeMax = '';
    this.prvtypeValue = '';
    this.prvTenor = '';
    this.prvtambahan = '';

    this.prvtypeMinSepa = '';
    this.prvtypeMaxSepa = '';
    this.prvtypeValueSepa = '';
    this.prvtambahanSepa = '';
  }

  validateRangePlafonPrv(model: any) {
    this.separator(model)
    if (this.dataPrv.length == 0) {
      if (this.prvtypeMin >= this.prvtypeMax) {
        this.warnTextprv = true;
      } else {
        this.warnTextprv = false;
      }
    } else {
      const lastData = this.dataPrv[this.dataPrv.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      if (parseInt(this.prvtypeMin) < newMaxPlafon) {
        this.prvtypeMin = String(newMaxPlafon);
        console.log('da');
      } else {
        console.log('xx')
      }
    }
  }


  tambahTb() {
    console.log(this.tbKondisi + ' -- ' + this.tbPlafon + ' -- ' + this.tbTambahan)
    if (this.tbKondisi == '' || this.tbPlafon == '' || this.tbTambahan == '') {
      this.util.showToast('Mohon Melengkapi Form Sebelum menambahkan Form Baru', 'danger', 'bottom');
    }
     else {

      this.form = JSON.stringify({
        cond: this.tbKondisi,
        plafon: this.tbPlafon,
        jenis: this.tbTambahantypepercent,
        value: this.tbTambahan,
        required: false,
      });
      console.log('form', this.form)

      if (this.dataTb.length == 0) {
        var t = '[' + this.form + ']';
        localStorage.setItem('34j3jh34jhg34jh3jh34jh3jh34jhj4sdfsfsdfs4234TB', t);
      } else {
          this.listBillx = localStorage.getItem('34j3jh34jhg34jh3jh34jh3jh34jhj4sdfsfsdfs4234TB')
          let aa = JSON.parse(this.listBillx);
          console.log('aa', aa)
          let gg = JSON.parse(this.form);
          console.log('notsame');
          var asa = this.listBillx.replace("]", "");
          let dataWithComa = ',' + this.form + ']';
          console.log('dataWithComa', dataWithComa);
          var j = asa + dataWithComa;
          localStorage.setItem('34j3jh34jhg34jh3jh34jh3jh34jhj4sdfsfsdfs4234TB', j);
        
      }
      this.tbKondisi = '';
      this.tbTambahantypepercent = 'NOMINAL';
      this.tbPlafon = '';
      this.tbPlafonSepa = '';
      this.tbTambahan = '';
      this.tbTambahanSepa = '';

      this.dataTb = JSON.parse(localStorage.getItem('34j3jh34jhg34jh3jh34jh3jh34jhj4sdfsfsdfs4234TB') || '');
    }
    // }
  }

  async deleteTb(i: any) {
    console.log('hapus')
    var indextodelete = i;
    var a = this.dataTb
    a.splice(indextodelete, 1);
    // PUT TO LS
    let x = JSON.stringify(a);
    console.log(x)
    localStorage.setItem('34j3jh34jhg34jh3jh34jh3jh34jhj4sdfsfsdfs4234TB', x);
    // GET
    let ax = localStorage.getItem('34j3jh34jhg34jh3jh34jh3jh34jhj4sdfsfsdfs4234TB') || '';
    this.dataTb = JSON.parse(ax);
    console.log('dataTb Hapus', this.dataTb);
    this.tbKondisi = '';
    this.tbTambahantypepercent = 'NOMINAL';
    this.tbPlafon = '';
    this.tbTambahan = '';
    this.tbTambahanSepa = '';
    this.tbPlafonSepa = '';

  }


  tambahStd() {
    console.log(this.stdTenor + ' -- ' + this.stdBiayaSepa + ' -- ' + this.stdBiaya)
    if (this.stdTenor == '' || this.stdBiaya == '') {
      this.util.showToast('Mohon Melengkapi Form Sebelum menambahkan Form Baru', 'danger', 'bottom');
      // this.admtypeValue = !this.admtypeValue;
    }
     else {

      this.form = JSON.stringify({
        tenor_id: this.stdTenor,
        jenis: this.stdtypepercent,
        value: this.stdBiaya,
        required: true,
      });

      console.log('form', this.form)

      if (this.dataStd.length == 0) {
        var t = '[' + this.form + ']';
        localStorage.setItem('87345yiu34h5g348734yu348734u3587uy34873yu34STD', t);
      } else {
          this.listBillx = localStorage.getItem('87345yiu34h5g348734yu348734u3587uy34873yu34STD')
          let aa = JSON.parse(this.listBillx);
          console.log('aa', aa)
          let gg = JSON.parse(this.form);
          console.log('notsame');
          var asa = this.listBillx.replace("]", "");
          let dataWithComa = ',' + this.form + ']';
          console.log('dataWithComa', dataWithComa);
          var j = asa + dataWithComa;
          localStorage.setItem('87345yiu34h5g348734yu348734u3587uy34873yu34STD', j);
        
      }
      this.stdTenor = '';
      this.stdtypepercent = 'NOMINAL';
      this.stdBiaya = '';
      this.stdBiayaSepa = '';

      this.dataStd = JSON.parse(localStorage.getItem('87345yiu34h5g348734yu348734u3587uy34873yu34STD') || '');
    }
    // }
  }

  async deleteStd(i: any) {
    console.log('hapus')
    var indextodelete = i;
    var a = this.dataStd
    a.splice(indextodelete, 1);
    // PUT TO LS
    let x = JSON.stringify(a);
    console.log(x)
    localStorage.setItem('87345yiu34h5g348734yu348734u3587uy34873yu34STD', x);
    // GET
    let ax = localStorage.getItem('87345yiu34h5g348734yu348734u3587uy34873yu34STD') || '';
    this.dataStd = JSON.parse(ax);
    console.log('dataStd Hapus', this.dataStd);
    this.stdTenor = '';
    this.stdtypepercent = 'NOMINAL';
    this.stdBiaya = '';
    this.stdBiayaSepa = '';
  }


  tambahAdm() {
    console.log(this.admtypeMin + ' -- ' + this.admtypeMax + ' -- ' + this.admtypeValue)
    if (this.admTenor == '' || this.admtypeMin == 0 || this.admtypeMax == '' || this.admtypeValue == '') {
      this.util.showToast('Mohon Melengkapi Form Sebelum menambahkan Form Baru', 'danger', 'bottom');
      // this.admtypeValue = !this.admtypeValue;
      this.admtypeValue = '';
      this.admtypeValueSepa = '';
    }
    else if (this.admtypeMin > this.admtypeMax) {
      this.warnTextadm = true;
      this.util.showToast('Minimal Harus Lebih Rendah dari Maksimal', 'danger', 'bottom');
      // this.admtypeValue = !this.admtypeValue;
      this.admtypeValue = '';
      this.admtypeValueSepa = '';

    } else {

      try {
        this.admtypeValue = this.admtypeValue.replace(',', '.');
      } catch (error) {
        console.log('nocomma')
      }

      this.form = JSON.stringify({
        tenor_id: this.admTenor,
        min_plafon: this.admtypeMin,
        max_plafon: this.admtypeMax,
        jenis: this.admtypepercent,
        value: this.admtypeValue,
        add_type: this.admtypetambahan,
        add_value: this.admtambahan
      });

      console.log('form', this.form)

      if (this.dataAdm.length == 0) {
        var t = '[' + this.form + ']';
        localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623ADM', t);
      } else {
        const lastData = this.dataAdm[this.dataAdm.length - 1];
        const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
        if (parseInt(this.admtypeMin) < lastMaxPlafon) {
          this.util.showToast('Mohon Perbaiki Validasi Input Range Maksimal & Minimal Tersebut', 'danger', 'bottom');
          // this.admtypeValue = !this.admtypeValue;
          this.admtypeValue = '';
          this.admtypeValueSepa = '';
        } else {
          this.listBillx = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623ADM')
          let aa = JSON.parse(this.listBillx);
          console.log('aa', aa)
          let gg = JSON.parse(this.form);
          console.log('notsame');
          var asa = this.listBillx.replace("]", "");
          let dataWithComa = ',' + this.form + ']';
          console.log('dataWithComa', dataWithComa);
          var j = asa + dataWithComa;
          localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623ADM', j);
        }
      }
      this.admtypeMin = '';
      this.admtypeMax = '';
      this.admtypeValue = '';
      this.admTenor = '';
      this.admtambahan = '';

      this.admtypeMinSepa = '';
      this.admtypeMaxSepa = '';
      this.admtypeValueSepa = '';
      this.admtambahanSepa = '';

      this.dataAdm = JSON.parse(localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623ADM') || '');

      const lastData = this.dataAdm[this.dataAdm.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      this.admtypeMin = newMaxPlafon;
      this.admtypeMinSepa = newMaxPlafon;
      console.log(newMaxPlafon);
    }
    // }
  }

  async deleteAdm(i: any) {
    console.log('hapus')
    var indextodelete = i;
    var a = this.dataAdm
    a.splice(indextodelete, 1);
    // PUT TO LS
    let x = JSON.stringify(a);
    console.log(x)
    localStorage.setItem('89234yug387g23e283dg823dg2837t238d72t37623ADM', x);
    // GET
    let ax = localStorage.getItem('89234yug387g23e283dg823dg2837t238d72t37623ADM') || '';
    this.dataAdm = JSON.parse(ax);
    console.log('dataAdm Hapus', this.dataAdm);
    this.admtypeMin = '';
    this.admtypeMax = '';
    this.admtypeValue = '';
    this.admTenor = '';
    this.admtypetambahan = '';

    this.admtypeMinSepa = '';
    this.admtypeMaxSepa = '';
    this.admtypeValueSepa = '';
    this.admtambahanSepa = '';
  }

  onChangeInput() {

  }

  validateRangePlafonAdm(model: any) {
    this.separator(model)
    if (this.dataAdm.length == 0) {
      if (this.admtypeMin >= this.admtypeMax) {
        this.warnTextadm = true;
      } else {
        this.warnTextadm = false;
      }
    } else {
      const lastData = this.dataAdm[this.dataAdm.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;

      if (parseInt(this.admtypeMin) < newMaxPlafon) {
        this.admtypeMin = String(newMaxPlafon);
        console.log('da');
      } else {
        console.log('xx')
      }
    }
  }

  clearDefault(field: string) {
    if (this[field] === 0) {
      this[field] = null;
    }
  }

  setDefault(field: string) {
    if (this[field] === null || this[field] === '') {
      this[field] = 0;
    }
  }

  onvalidateRangePlafonPrv(event: KeyboardEvent) {
    if (this.dataPrv.length == 0) {
      if (this.prvtypeMin >= this.prvtypeMax) {
        this.warnTextprv = true;
      } else {
        this.warnTextprv = false;
      }
      console.log('prvtypeMin', this.prvtypeMin)
      console.log('prvtypeMax', this.prvtypeMax)
    } else {
      console.log('prvtypeMin', this.prvtypeMin)
      console.log('prvtypeMax', this.prvtypeMax)
      const lastData = this.dataPrv[this.dataPrv.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      console.log(newMaxPlafon + ' < ' + parseInt(this.prvtypeMin))
      console.log('newMaxPlafon', newMaxPlafon)
      console.log('prvtypeMin', parseInt(this.prvtypeMin))
      console.log('prvtypeMax', this.prvtypeMax)
      if (parseInt(this.prvtypeMin) < lastMaxPlafon) {
        // this.admtypeMin = String(newMaxPlafon); // Kembalikan nilai ke lastMaxPlafon
        console.log(this.prvtypeMin)
        this.warnTextprv2 = true;
      } else {
        this.warnTextprv2 = false;
      }

      if (this.prvtypeMin >= this.prvtypeMax) {
        this.warnTextprv = true;
      } else {
        this.warnTextprv = false;
      }
    }

  }

  onvalidateRangePlafonAdm(event: KeyboardEvent) {
    if (this.dataAdm.length == 0) {
      if (this.admtypeMin >= this.admtypeMax) {
        this.warnTextadm = true;
      } else {
        this.warnTextadm = false;
      }
      console.log('admtypeMin1', this.admtypeMin)
      console.log('admtypeMax1', this.admtypeMax)
    } else {
      console.log('admtypeMin1', this.admtypeMin)
      console.log('admtypeMax1', this.admtypeMax)
      const lastData = this.dataAdm[this.dataAdm.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      console.log(newMaxPlafon + ' < ' + parseInt(this.admtypeMin))
      console.log('newMaxPlafon', newMaxPlafon)
      console.log('admtypeMin2', parseInt(this.admtypeMin))
      console.log('admtypeMax2', this.admtypeMax)
      if (parseInt(this.admtypeMin) < lastMaxPlafon) {
        console.log(this.admtypeMin)
        this.warnTextadm2 = true;
      } else {
        this.warnTextadm2 = false;
      }

      if (this.admtypeMin >= this.admtypeMax) {
        this.warnTextadm = true;
      } else {
        this.warnTextadm = false;
      }
    }

  }

  onvalidateRangePlafonCrc(event: KeyboardEvent) {
    if (this.dataCrc.length == 0) {
      if (this.crctypeMin >= this.crctypeMax) {
        this.warnTextcrc = true;
      } else {
        this.warnTextcrc = false;
      }
      console.log('crctypeMin', this.crctypeMin)
      console.log('crctypeMax', this.crctypeMax)
    } else {
      console.log('crctypeMin', this.crctypeMin)
      console.log('crctypeMax', this.crctypeMax)
      const lastData = this.dataCrc[this.dataCrc.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      console.log(newMaxPlafon + ' < ' + parseInt(this.crctypeMin))
      console.log('newMaxPlafon', newMaxPlafon)
      console.log('crctypeMin', parseInt(this.crctypeMin))
      console.log('crctypeMax', this.crctypeMax)
      if (parseInt(this.crctypeMin) < lastMaxPlafon) {
        console.log(this.crctypeMin)
        this.warnTextcrc2 = true;
      } else {
        this.warnTextcrc2 = false;
      }

      if (this.crctypeMin >= this.crctypeMax) {
        this.warnTextcrc = true;
      } else {
        this.warnTextcrc = false;
      }
    }

  }


  onvalidateRangePlafonAjm(event: KeyboardEvent) {
    if (this.dataAjm.length == 0) {
      if (this.ajmtypeMin >= this.ajmtypeMax) {
        this.warnTextajm = true;
      } else {
        this.warnTextajm = false;
      }
      console.log('ajmtypeMin', this.ajmtypeMin)
      console.log('ajmtypeMax', this.ajmtypeMax)
    } else {
      console.log('ajmtypeMin', this.ajmtypeMin)
      console.log('ajmtypeMax', this.ajmtypeMax)
      const lastData = this.dataAjm[this.dataAjm.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      console.log(newMaxPlafon + ' < ' + parseInt(this.ajmtypeMin))
      console.log('newMaxPlafon', newMaxPlafon)
      console.log('ajmtypeMin', parseInt(this.ajmtypeMin))
      console.log('ajmtypeMax', this.ajmtypeMax)
      if (parseInt(this.ajmtypeMin) < lastMaxPlafon) {
        console.log(this.ajmtypeMin)
        this.warnTextajm2 = true;
      } else {
        this.warnTextajm2 = false;
      }

      if (this.ajmtypeMin >= this.ajmtypeMax) {
        this.warnTextajm = true;
      } else {
        this.warnTextajm = false;
      }
    }

  }

  onvalidateRangePlafonAjw(event: KeyboardEvent) {
    if (this.dataAjw.length == 0) {
      if (this.ajwtypeMin >= this.ajwtypeMax) {
        this.warnTextajw = true;
      } else {
        this.warnTextajw = false;
      }
      console.log('ajwtypeMin', this.ajwtypeMin)
      console.log('ajwtypeMax', this.ajwtypeMax)
    } else {
      console.log('ajwtypeMin', this.ajwtypeMin)
      console.log('ajwtypeMax', this.ajwtypeMax)
      const lastData = this.dataAjw[this.dataAjw.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      console.log(newMaxPlafon + ' < ' + parseInt(this.ajwtypeMin))
      console.log('newMaxPlafon', newMaxPlafon)
      console.log('ajwtypeMin', parseInt(this.ajwtypeMin))
      console.log('ajwtypeMax', this.ajwtypeMax)
      if (parseInt(this.ajwtypeMin) < lastMaxPlafon) {
        console.log(this.ajwtypeMin)
        this.warnTextajw2 = true;
      } else {
        this.warnTextajw2 = false;
      }

      if (this.ajwtypeMin >= this.ajwtypeMax) {
        this.warnTextajw = true;
      } else {
        this.warnTextajw = false;
      }
    }

  }

  validateRangePlafonprv(model: any) {
    this.separator(model)
    if (this.dataPrv.length == 0) {
      if (this.prvtypeMin >= this.prvtypeMax) {
        this.warnTextprv = true;
      } else {
        this.warnTextprv = false;
      }
      console.log('prvtypeMin', this.prvtypeMin)
      console.log('prvtypeMax', this.prvtypeMax)
    } else {
      const lastData = this.dataPrv[this.dataPrv.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      console.log(newMaxPlafon + ' < ' + parseInt(this.prvtypeMin))
      console.log('newMaxPlafon', newMaxPlafon)
      console.log('prvtypeMin', parseInt(this.prvtypeMin))
      console.log('prvtypeMax', this.prvtypeMax)
      if (parseInt(this.prvtypeMin) < newMaxPlafon) {
        this.prvtypeMin = String(newMaxPlafon);
        console.log('da');
        this.warnTextprv2 = true;
      } else {
        console.log('xx')
        this.warnTextprv2 = true;

      }
    }
  }


  validateRangePlafoncrc(model: any) {
    this.separator(model)
    if (this.dataCrc.length == 0) {
      if (this.crctypeMin >= this.crctypeMax) {
        this.warnTextcrc = true;
      } else {
        this.warnTextcrc = false;
      }
    } else {
      const lastData = this.dataCrc[this.dataCrc.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;

      if (parseInt(this.crctypeMin) < newMaxPlafon) {
        this.crctypeMin = String(newMaxPlafon);
        // this.warnTextcrc2 = true;
      } else {
        console.log('xx')
        // this.warnTextcrc2 = false;

      }
    }
  }

  validateRangePlafonajm() {
    if (this.dataAjm.length == 0) {
      if (this.ajmtypeMin >= this.ajmtypeMax) {
        this.warnTextajm = true;
      } else {
        this.warnTextajm = false;
      }
      console.log('ajmtypeMin', this.ajmtypeMin)
      console.log('ajmtypeMax', this.ajmtypeMax)
    } else {
      const lastData = this.dataAjm[this.dataAjm.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      console.log(newMaxPlafon + ' < ' + parseInt(this.ajmtypeMin))
      console.log('newMaxPlafon', newMaxPlafon)
      console.log('ajmtypeMin', parseInt(this.ajmtypeMin))
      console.log('ajmtypeMax', this.ajmtypeMax)
      if (parseInt(this.ajmtypeMin) < newMaxPlafon) {
        this.ajmtypeMin = String(newMaxPlafon);
        console.log('da');
        this.warnTextajm2 = true;
      } else {
        console.log('xx')
        this.warnTextajm2 = false;
      }
    }
  }

  validateRangePlafonajw() {
    if (this.dataAjw.length == 0) {
      if (this.ajwtypeMin >= this.ajwtypeMax) {
        this.warnTextajm = true;
      } else {
        this.warnTextajm = false;
      }
      console.log('ajwtypeMin', this.ajwtypeMin)
      console.log('ajwtypeMax', this.ajwtypeMax)
    } else {
      const lastData = this.dataAjw[this.dataAjw.length - 1];
      const lastMaxPlafon = parseInt(lastData.max_plafon, 10);
      const newMaxPlafon = lastMaxPlafon + 1;
      console.log(newMaxPlafon + ' < ' + parseInt(this.ajwtypeMin))
      console.log('newMaxPlafon', newMaxPlafon)
      console.log('ajwtypeMin', parseInt(this.ajwtypeMin))
      console.log('ajwtypeMax', this.ajwtypeMax)
      if (parseInt(this.ajwtypeMin) < newMaxPlafon) {
        this.ajwtypeMin = String(newMaxPlafon);
        console.log('da');
        this.warnTextajm2 = true;
      } else {
        console.log('xx')
        this.warnTextajm2 = true;
      }
    }
  }

  tambahJam() {
    console.log(this.pilihJam + '===' + this.pemilikJam)
    if (this.pilihJam == '') {
      this.util.showToast('Mohon Melengkapi Form Sebelum menambahkan Form Baru', 'danger', 'bottom');
    } else {
      var arrayData = this.pemilikJam.split(',').map(function (item) {
        return item.trim();
      });
      this.form = JSON.stringify({
        type_id: this.pilihJam,
        owner_id: this.peJam
      });

      if (this.dataChar == '') {
        var t = '[' + this.form + ']';
        localStorage.setItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj', t);
      } else {
        this.listBillx = localStorage.getItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj')
        let aa = JSON.parse(this.listBillx);
        console.log('aa', aa)
        let gg = JSON.parse(this.form);
        aa.push(gg);
        console.log('bb', aa)
        var cc = JSON.stringify(aa);
        localStorage.setItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj', cc);
      }
      this.pilihJam = '';
      this.pemilikJam = '';
      // this.getPointChar = '';
      this.dataChar = JSON.parse(localStorage.getItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj') || '');
    }
  }

  async deleteCapacity(i: any) {
    console.log('hapus')
    var indextodelete = i;
    var a = this.dataChar
    a.splice(indextodelete, 1);
    // PUT TO LS
    let x = JSON.stringify(a);
    console.log(x)
    localStorage.setItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj', x);
    // GET
    let ax = localStorage.getItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj') || '';
    this.dataChar = JSON.parse(ax);
    console.log('dataChar Hapus', this.dataChar)
  }



  handleSelection(selectedValue: string, model: any) {
    if (model == 'cabang') {
      this.cabang = selectedValue;
    } else if (model == 'grup') {
      this.grup = selectedValue;
    }
  }

  dismiss() {
    this.api.dismiss()
  }
}
