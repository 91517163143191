import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util/util.service';
declare var google;

@Component({
  selector: 'app-user-cabang-add',
  templateUrl: './user-cabang-add.component.html',
  styleUrls: ['./user-cabang-add.component.scss'],
})
export class UserCabangAddComponent implements OnInit {

  load: boolean = false;
  saving: any = {};
  idData: any;

  detData: any = {};
  det: any;
  tampilData: boolean = false;
 
  cabang: any = '';
  grup: any = '';
  nama: any = '';
  radius: any = '';
  jabatan: any = '';
  phone: any = '';
  email: any = '';
  username: any = '';
  password: any = '';

  usertype: any = '';

  toleransi: any = '';
  pulang: any = '';
  masuk: any = '';

  disabledInput: boolean = false;
  mapsx: any = '';
  latitude: any = -6.175056284357598;
  longitude: any = 106.82715794388344;

  tampilAlamat: boolean = false;
  latlong: any = '';

  dataAlamat: any = {};
  dataAlamatDom: any = {};
  dataAlamatKirim: any = '';

  kelurahanktp: any = '';
  kelurahanId: any = '';
  alamatktp: any = '';

  dataAlamatJSON: any;


  constructor(
    public api: ApiService,
    public util: UtilService,
    public navParams: NavParams
  ) {
    
   }

  ngOnInit() {
    this.idData = this.navParams.data.id;
    // this.initMap();
    console.log(this.idData);
    if(this.idData){
      this.disabledInput = true;
      this.detailData()
    }else{
      this.disabledInput = false;
      this.tampilData = true;
      this.tampilAlamat = true
    }
  }

  ionViewWillEnter(){
    // this.mapsx = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBiK5-chVyfuv43pco55C7bBvyugH3zWiU&q=' + this.latitude + ',' + this.longitude;
    // console.log('latitude', this.latitude, ' + longitude', this.longitude);
  }

  dateSelection(data: any) {
    // console.log('dateSelection',data)
    this.masuk = data;
  }

  dateSelectionout(data: any) {
    // console.log('dateSelection',data)
    this.pulang = data;
  }

  handleData(data: any) {
    // this.dataAlamatKirim = data;
    this.dataAlamat = JSON.parse(data);
    console.log('dataAlamat',this.dataAlamat)
    this.kelurahanktp = this.dataAlamat.kelurahanktp;
    this.alamatktp = this.dataAlamat.alamatktp;
    this.kelurahanId = this.dataAlamat.kelurahanIdktp;

  }

  async initMap() {
    // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps");
    const myLatlng = { lat: this.latitude, lng: this.longitude };
    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 6,
      center: myLatlng,
      disableDefaultUI: true,
    });
    // Create the initial InfoWindow.
    let infoWindow = new google.maps.InfoWindow({
      content: "Click the map to get Lat/Lng!",
      position: myLatlng,
    });
  
    infoWindow.open(map);
    // Configure the click listener.
    map.addListener("click", (mapsMouseEvent) => {
      // Close the current InfoWindow.
      infoWindow.close();
      // Create a new InfoWindow.
      infoWindow = new google.maps.InfoWindow({
        position: mapsMouseEvent.latLng,
      });
      infoWindow.setContent(
        JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2),
      );
      infoWindow.open(map);
    });
  }
  
  toMaps(){
    var url = 'https://www.google.com/maps';
    console.log('url', url);
    // window.location.href = url;
    window.open(url, '_blank');
  }

  detailData(){
    this.api.get('backoffice/cabang/'+this.idData)
      .subscribe((data) => {
        this.detData = data;
        if (this.detData.error == 0) {
          this.nama = this.detData.data.name;
          this.radius = this.detData.data.radius_absensi;
          this.masuk = this.detData.data.jam_kerja_in;
          this.pulang = this.detData.data.jam_kerja_out;
          this.toleransi = this.detData.data.tolerance_telat;
          this.latlong = this.detData.data.latitude+', '+this.detData.data.longitude;
          this.dataAlamatJSON = JSON.stringify({
            "ktp_subdistricts_name" : this.detData.data.subdistricts_name+', '+this.detData.data.districts+', '+this.detData.data.city+', '+this.detData.data.province,
            "ktp_address" : this.detData.data.address,
            "ktp_subdistricts_id" : this.detData.data.subdistricts_id,
          })
          this.alamatktp = this.detData.data.address;
          this.kelurahanId = this.detData.data.subdistricts_id;
          setTimeout(() => {
            this.tampilData = true;
          this.load = false;
          this.tampilAlamat = true;
            }, 1000);
        } else {
          this.load = false;
          this.util.showToast(this.detData.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  save(){
    this.load = true;
    var latLongArray = this.latlong.split(','); 
    this.latitude = latLongArray[0].trim();
    this.longitude = latLongArray[1].trim();

    let form = {
      name: this.nama,
      jam_kerja_in: this.masuk,
      jam_kerja_out:this.pulang,
      tolerance_telat: this.toleransi,
      subdistricts_id: this.kelurahanId,
      radius_absensi: this.radius,
      alamat_lengkap: this.alamatktp,
      longitude: this.longitude,
      latitude: this.latitude
    };
    console.log(form)
    if(this.idData){
      this.api.put('backoffice/cabang/'+this.idData, form)
      .subscribe((data) => {
        this.saving = data;
        if (this.saving.error == 0) {
          this.load = false;
          this.util.showToast(this.saving.message, 'success', 'bottom');
          this.api.dismissData('','confirm');

        } else {
          this.load = false;
          this.util.showToast(this.saving.message, 'danger', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
    }else{
    this.api.post('backoffice/cabang', form)
      .subscribe((data) => {
        this.saving = data;
        if (this.saving.error == 0) {
          this.load = false;
          this.util.showToast(this.saving.message, 'success', 'bottom');
          this.api.dismissData('','confirm');

        } else {
          this.load = false;
          this.util.showToast(this.saving.message, 'danger', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
    }
  }

  handleSelection(selectedValue: string, model: any) {
    if (model == 'cabang') {
      this.cabang = selectedValue;
    } else if (model == 'usertype') {
      this.usertype = selectedValue;
    } 
  }

  dismiss() {
    this.api.dismiss()
  }
}
